import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Form, Popover, OverlayTrigger, Button } from "react-bootstrap";
import { MessageLoading } from "../MessageLoading/MessageLoading";
import { ButtonInkup } from "../Button/ButtonInkup";
import { Text } from "./components/Text";
import "./info.css";

export const Info = ({ block, props }) => {
  const [t] = useTranslation("global");
  const [messageLoading, setMessageLoading] = useState(null);
  const [showPopover, setShowPopover] = useState(false);

  useEffect(() => {
    props.setShowWriting(true);
    setMessageLoading(true);
    setTimeout(() => {
      setMessageLoading(false);
    }, 1000);

    setTimeout(() => {
      setMessageLoading(null);
    }, 2000);

    setTimeout(() => {
      props.setShowWriting(false);
      setMessageLoading(3);
    }, 3000);
  }, []);

  const handleChange = () => {
    props.setCheckTerms(true);
    if (props.checkTerms === false) {

      props.handleSave2();
    }
  };

  const popover = (
    <Popover id="popover-basic" className="custom-popover">
      <div className="div-button-popover">
        <button onClick={() => setShowPopover(false)}>X</button>
      </div>
      <Popover.Body>
        <p className="terms-conditions">
          {t("popoverTermsAndConditions.text1")}
        </p>
        <p className="conditions">{t("popoverTermsAndConditions.text2")}</p>
        <p className="text-grey">{t("popoverTermsAndConditions.text3")}</p>
        <p className="text-grey">
          {t("popoverTermsAndConditions.text4")}
          <a href="https://www.inkup.io" target="_blank" rel="noreferrer">
            www.inkup.io
          </a>
        </p>
        <p className="text-grey">{t("popoverTermsAndConditions.text5")}</p>
        <p className="text-grey">
          {t("popoverTermsAndConditions.text6")}
          <a href="https://inkup.io/tyc" target="_blank" rel="noreferrer">
            https://inkup.io/tyc
          </a>
        </p>
        <p className="text-grey">{t("popoverTermsAndConditions.text7")}</p>
        <p className="conditions">{t("popoverTermsAndConditions.text8")}</p>
        <p className="text-grey">{t("popoverTermsAndConditions.text9")}</p>
        <p className="text-grey">{t("popoverTermsAndConditions.text10")}</p>
        <p className="text-grey">{t("popoverTermsAndConditions.text11")}</p>
        <p className="text-grey">{t("popoverTermsAndConditions.text12")}</p>
        <p className="text-grey">{t("popoverTermsAndConditions.text13")}</p>
        <p className="text-grey">{t("popoverTermsAndConditions.text14")}</p>
        <p className="conditions">{t("popoverTermsAndConditions.text15")}</p>
        <p className="text-grey"> {t("popoverTermsAndConditions.text16")}</p>
        <p className="text-grey">{t("popoverTermsAndConditions.text17")}</p>
        <p className="conditions">{t("popoverTermsAndConditions.text18")}</p>
        <p className="text-grey">{t("popoverTermsAndConditions.text19")}</p>
        <p className="text-grey">{t("popoverTermsAndConditions.text20")}</p>
        <p className="text-grey">{t("popoverTermsAndConditions.text21")}</p>
        <p className="text-grey">{t("popoverTermsAndConditions.text22")}</p>
        <p className="text-grey">{t("popoverTermsAndConditions.text23")}</p>
        <p className="conditions">{t("popoverTermsAndConditions.text24")}</p>
        <p className="text-grey">{t("popoverTermsAndConditions.text25")}</p>
        <p className="conditions">{t("popoverTermsAndConditions.text26")}</p>
        <p className="text-grey">{t("popoverTermsAndConditions.text27")}</p>
        <p className="conditions">{t("popoverTermsAndConditions.text28")}</p>
        <p className="text-grey">{t("popoverTermsAndConditions.text29")}</p>
        <p className="conditions">{t("popoverTermsAndConditions.text30")}</p>
        <p className="text-grey">{t("popoverTermsAndConditions.text30a")}</p>
        <p className="text-grey">{t("popoverTermsAndConditions.text31")}</p>
        <p className="text-grey">{t("popoverTermsAndConditions.text32")}</p>
        <p className="text-grey">{t("popoverTermsAndConditions.text33")}</p>
        <p className="text-grey">{t("popoverTermsAndConditions.text34")}</p>
        <p className="text-grey">{t("popoverTermsAndConditions.text35")}</p>
        <p className="text-grey">{t("popoverTermsAndConditions.text36")}</p>
        <p className="text-grey">
          {t("popoverTermsAndConditions.text37")}
          <a href="mailto:info@inkup.io">info@inkup.io</a>
        </p>
        <p className="text-grey">{t("popoverTermsAndConditions.text38")}</p>
        <p className="conditions">{t("popoverTermsAndConditions.text39")}</p>
        <p className="text-grey">{t("popoverTermsAndConditions.text40")}</p>
        <p className="text-grey">{t("popoverTermsAndConditions.text41")}</p>
      </Popover.Body>
    </Popover>
  );

  return (
    <>
      {messageLoading === true ? (
        <MessageLoading imgLogoInitial={props.initailOrImage} />
      ) : (
        <Text props={props} />
      )}

      {messageLoading === null && (
        <MessageLoading imgLogoInitial={props.initailOrImage} />
      )}
      {messageLoading === 3 && (
        <>
          <Form.Group id="">
            <Form.Group
              className="divTermsFormChat paddingButtonCheck"
              controlId="formBasicCheckbox"
            >
              <Form.Check.Input
                id="idCheckTermsFormChat"
                className={props?.styles?.color_text_carousel !== null ? "inputRadioFormChat sizeInputTermsFormChatCarousel" : "inputRadioFormChat sizeInputTermsFormChat2"}
                type="checkBox"
                onChange={() => handleChange()}
                checked={props.checkTerms}
              />
              <span className={props?.styles?.color_text_carousel !== null ? "pTerminosFormChatCarousel" : "pTerminosFormChat2  "}>
                {t("signup.accept")} {t("signup.terms")}
              </span>
            </Form.Group>
            <div
              className={`divButtonFormTemplate ${props.checkTerms ? "bounce " : ""
                }`}
            >
              <ButtonInkup disabled={props.checkTerms} disabledStatus={props.status} props={props} />
            </div>
            <Form.Group
              className="divTermsFormChat"
              controlId="formBasicCheckbox"
            >
              <OverlayTrigger
                trigger="click"
                placement="top"
                overlay={popover}
                show={showPopover}
                rootClose={true}
              >
                <Button
                  className={props?.styles?.color_text_carousel !== null ? "spamTerminosFormChatCarousel" : "spamTerminosFormChat2  "}
                  variant="success"
                  onClick={() => setShowPopover(!showPopover)}
                >
                  {t("info.showTerms")}
                </Button>
              </OverlayTrigger>
            </Form.Group>
          </Form.Group>
        </>
      )}
    </>
  );
};
