//    INFO
export const DataTattoo = () => {
  return [
    {
      _uid: "BUY6Drn1e1",
      component: "welcome",
      headline: "Welcome",
      conditional: true,
    },

    {
      _uid: "BUY6Drn2e4",
      component: "check_query",
      headline: "check_query",
      conditional: false,
    },
    {
      _uid: "BUY6Drn1e2",
      component: "name_budget",
      headline: "name_budget",
      conditional: false,
    },
    {
      _uid: "BUY6Drn1e5",
      component: "size",
      headline: "size",
      conditional: false,
    },
    {
      _uid: "BUY6Drn1e7",
      component: "body_part",
      headline: "body_part",
      conditional: false,
    },
    {
      _uid: "BUY6Drn1e6",
      component: "budget_color",
      headline: "budget_color",
      conditional: false,
    },
    {
      _uid: "BUY6Drn1e9",
      component: "images",
      headline: "images",
      conditional: false,
    },
    {
      _uid: "BUY6Drn1e8",
      component: "description",
      headline: "description",
      conditional: false,
    },
    {
      _uid: "BUY6Drn2e8",
      component: "finish_text",
      headline: "finish_text",
      conditional: false,
    },
    {
      _uid: "BUY6Drn2e9",
      component: "email_phone_budget",
      headline: "  email_phone_budget",
      conditional: false,
    },
    {
      _uid: "BUY6Drn2e5",
      component: "info",
      headline: "info",
      conditional: false,
    },
  ];
};
