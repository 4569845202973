import { countryMapping } from './CountryMapping';

const countryOrder = {
    "US": 1,
    "CA": 3,
    "ES": 2,
    "CH": 4,
  };


export function getCountryName(countryCode, language) {
    if(language === 'es'){
      return countryMapping[countryCode].emoji + ' ' + countryMapping[countryCode].spName;
    }else if(language === 'de'){
      return countryMapping[countryCode].emoji + ' ' + countryMapping[countryCode].deName;
    }else{
      return countryMapping[countryCode].emoji + ' ' + countryMapping[countryCode].name;
    }
}

export function getUniqueCountries(data) {
    const uniqueCountries = new Set();
    data.forEach(item => {
      uniqueCountries.add(item.country);
    });
    const countriesArray = Array.from(uniqueCountries);
    countriesArray.sort((a, b) => {
      const orderA = countryOrder[a] !== undefined ? countryOrder[a] : Infinity;
      const orderB = countryOrder[b] !== undefined ? countryOrder[b] : Infinity;
      return orderA - orderB;
    });

    return countriesArray;
  }

export function filterByCountry(country, data) {
    return data.filter(item => item.country === country);
}