import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Form } from "react-bootstrap";
import { MessageLoading } from "../MessageLoading/MessageLoading";
import { DotsComponents } from "../DotsComponent/DotsComponents";
import {
  nextComponent,
  setFormComponent,
  setFormComponentInfo,
} from "../../utils/nextComponent";
import { IconArrowRightAnimation } from "../Icons/Icon-arrow-right/IconArrowRightAnimation";
import imageDefaultArtis from "../../../../assets/images/artists-img-default.png";

export const Employeds = ({ block, props }) => {
  const [t] = useTranslation("global");
  const [employed, setEmployed] = useState("");
  const [messageLoading, setMessageLoading] = useState(null);
  const myRef2 = useRef(null);
  const [dot, setDot] = useState(0);

  useEffect(() => {
    if (block.conditional) {
      props.setShowWriting(true);
      setMessageLoading(true);
      setTimeout(() => {
        setMessageLoading(false);
        props.setShowWriting(false);
        executeScroll();
      }, 1000);
    }
  }, []);

  const executeScroll = () => {
    setTimeout(() => {
      if (myRef2?.current) {
        myRef2.current.scrollIntoView({ behavior: "smooth" });
      }
    }, 100);
  };

  const handleChangeEmployed = (name, i) => {
    setEmployed(name);
    setFormComponent(props, block, { check_employeds: name });
    setFormComponentInfo(props, block, {
      check_employeds:
        name === "Prefiero asesorarme" ? t("employeds.iDoNotKnow") : name,
    });
    nextComponent(props, block);
    setDot(i);
  };

  useEffect(() => {
    let employedInfo = props.employeds.filter(
      (data) => data?.name === employed && "employeds.iDoNotKnow"
    );
    if (employedInfo[0]?.name === "Prefiero asesorarme") {
      setFormComponentInfo(props, block, {
        check_employeds: t("employeds.iDoNotKnow"),
      });
    }
  }, [props.language]);

  return (
    <>
      {messageLoading === true && <MessageLoading />}
      {messageLoading === false && (
        <>
          <Form.Group id="nombre">
            <div
              ref={
                props.arrayComponentInitial[1].component === block.component
                  ? null
                  : myRef2
              }
              className="divGenericLabelFormChat marginTopLabelGenericFormChat"
            >
              <Form.Label className="generic01LabelFormChat genericLabelFormChatBubble borderColorLabelVar backgroundColorContainer">
                <span className="textBoldFormChat">
                  {t("employeds.employedName")}
                </span>{" "}
              </Form.Label>
            </div>
          </Form.Group>
          {props?.employeds?.length > 3 && <IconArrowRightAnimation />}
          <Form.Group className="pFC">
            <div
              id="idArtistFormChat"
              className="divGenericBodyPartFormChat divUnsetScrollFormChat"
            >
              <div className="divScrollBodypart">
                {props.employeds.map((data, index) => (
                  <div key={index}>
                    <Form.Check
                      type="radio"
                      id={`check-api-${data.id_employed}
                  `}
                    >
                      <div
                        // className="divBodyPartFormChat divBodyPartFormChatEmployed backgroundCarrousel"
                        className="divBodyPartFormChat divBodyPartFormChatEmployed"
                        style={
                          data.image
                            ? {
                                backgroundImage: props.styles
                                  ? `linear-gradient(${props.styles?.color_filter}, ${props.styles?.color_filter}), url(${data.image})`
                                  : `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(${data.image})`,
                              }
                            : {
                                backgroundImage: props.styles
                                  ? `linear-gradient(${props.styles?.color_filter}, ${props.styles?.color_filter}), url(${imageDefaultArtis})`
                                  : `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(${imageDefaultArtis})`,
                              }
                        }
                      >
                        <Form.Check.Input
                          className="inputRadioFormChat"
                          type="radio"
                          isValid
                          checked={employed === data.name}
                          onChange={() =>
                            handleChangeEmployed(data.name, index)
                          }
                        />

                        <Form.Check.Label className=" divTextDefinitionLabelImage"></Form.Check.Label>
                      </div>
                      <div className="divTextBodyPartPiercing">
                        <p className={
                            props?.styles?.color_text_carousel !== null
                              ? "colorTextVarCarousel m-0"
                              : "colorTextVar m-0"
                          }>
                          {data.name === "Prefiero asesorarme"
                            ? `${t("employeds.iDoNotKnow")}`
                            : data.name}
                        </p>
                      </div>
                    </Form.Check>
                  </div>
                ))}
              </div>
            </div>
          </Form.Group>
          <DotsComponents dotNumber={props.employeds.length} iDot={dot} />
        </>
      )}
    </>
  );
};
