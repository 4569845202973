import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import imageBottle from "../../assets/images/bottle_ink_white.svg";
import "./error404.css";
import React from 'react';

export const Error404PageNotFound = () => {
  const [t] = useTranslation("global");

  return (
    <>
      <Row className="text-white m-0 row-error404 urbanist">
        <Col className="p-0 col-text-404">
          <div className="div-text-404">
            <h2>{t("Error404.h2")}</h2>
            <p className="p-404">{t("Error404.p404")}</p>
            <p className="p-text">{t("Error404.paragraph")}</p>
            <img src={imageBottle} alt="Imagen Ink"></img>
          </div>
        </Col>
      </Row>
    </>
  );
};
