import React from "react";
import { IconPlus } from "../../../../Icons/Icon-plus/IconPlus";

export const PButtonUploadImage = ({ onImageUpload, text, maxImage }) => {
  return (
    <div className="divBodyPartFormChat">
      <div className="file-upload-coverpage" style={{ border: "none" }}>
        <div className="no-data-container-template">
          <button className="buttonCPImage  ">
            <input type="file" onChange={(e) => onImageUpload(e)} multiple />
            <IconPlus />
            <p className="colorTextVar mb-0">{text}</p>
            <p className="colorTextVar">{maxImage}</p>
          </button>
        </div>
      </div>
    </div>
  );
};
