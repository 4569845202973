import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";

export const Description = ({ user, scrollY }) => {
  const [descriptionClassName, setDescriptionClassName] =
    useState("divDescription");

  useEffect(() => {
    setTimeout(() => {
      if (scrollY !== 0) {
        setDescriptionClassName("divDescription title-hide");
      }
    }, 2000);
  }, [scrollY]);

  return (
    <Row>
      <Col>
        {user.about_me !== "" && (
          <div className={descriptionClassName}>
            <p className="fontVarDescription">{user.about_me}</p>
          </div>
        )}
      </Col>
    </Row>
  );
};
