//    INFO
export const DataPiercing = () => {
  return [
    {
      _uid: "BUY6Drn1e1",
      component: "welcome",
      headline: "Welcome",
      conditional: true,
    },
    {
      _uid: "BUY6Drn1e2",
      component: "name_budget",
      headline: "name_budget",
      conditional: false,
    },
    {
      _uid: "BUY6Drn1e7",
      component: "body_part",
      headline: "body_part",
      conditional: false,
    },
    {
      _uid: "BUY6Drn2e2",
      component: "time_availability",
      headline: "time_availability",
      conditional: false,
    },
    {
      _uid: "BUY6Drn2e7",
      component: "old_client",
      headline: "old_client",
      conditional: false,
    },
    {
      _uid: "BUY6Drn2e9",
      component: "email_phone_budget",
      headline: "  email_phone_budget",
      conditional: false,
    },
    {
      _uid: "BUY6Drn2e5",
      component: "info",
      headline: "info",
      conditional: false,
    },
  ];
};
