import React, { useEffect, useRef } from "react";
import { Button, Form, OverlayTrigger, Popover } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { IconWhatsApp } from "../Icons/Icon-WhatsApp/IconWhatsApp";
import iconEmail from "../../../../assets/images/Icon-Email.svg";
import { TermsAndConditions } from "../../TermsAndConditions/TermsAndConditions";
import { servicesTextMessageResponse } from "../Services/servicesTextMessageResponse";

export const ButtonOtherServices = ({ props }) => {
  const [t] = useTranslation("global");
  let text1BottomFormChat = `${t("SendWhatsAppForm.buttonSendForWhatsApp")}`;
  let text3BottomFormChat = `${t("info.buttonSendEmail")}`;
  let optionButton = props.optionButtonServices;
  const myRef = useRef(null);

  useEffect(() => {
    executeScroll();
  }, [props]);

  const executeScroll = () => {
    setTimeout(() => {
      const element = myRef?.current;
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
        const elementRect = element.getBoundingClientRect();
        const absoluteElementTop = elementRect.top + window.scrollY;
        const middle = absoluteElementTop - window.innerHeight / 2;
        window.scrollTo({ top: middle, behavior: "smooth" });
        element.focus();
      }
    }, 500);
  };

  const popover = (
    <Popover id="popover-basic" className="custom-popover">
      <div className="div-button-popover">
        <button onClick={() => props.setShowPopover(false)}>X</button>
      </div>
      <Popover.Body>
        <p className="terms-conditions">
          {t("popoverTermsAndConditions.text1")}
        </p>
        <p className="conditions">{t("popoverTermsAndConditions.text2")}</p>
        <p className="text-grey">{t("popoverTermsAndConditions.text3")}</p>
        <p className="text-grey">
          {t("popoverTermsAndConditions.text4")}
          <a href="https://www.inkup.io" target="_blank" rel="noreferrer">
            www.inkup.io
          </a>
        </p>
        <p className="text-grey">{t("popoverTermsAndConditions.text5")}</p>
        <p className="text-grey">
          {t("popoverTermsAndConditions.text6")}
          <a href="https://inkup.io/tyc" target="_blank" rel="noreferrer">
            https://inkup.io/tyc
          </a>
        </p>
        <p className="text-grey">{t("popoverTermsAndConditions.text7")}</p>
        <p className="conditions">{t("popoverTermsAndConditions.text8")}</p>
        <p className="text-grey">{t("popoverTermsAndConditions.text9")}</p>
        <p className="text-grey">{t("popoverTermsAndConditions.text10")}</p>
        <p className="text-grey">{t("popoverTermsAndConditions.text11")}</p>
        <p className="text-grey">{t("popoverTermsAndConditions.text12")}</p>
        <p className="text-grey">{t("popoverTermsAndConditions.text13")}</p>
        <p className="text-grey">{t("popoverTermsAndConditions.text14")}</p>
        <p className="conditions">{t("popoverTermsAndConditions.text15")}</p>
        <p className="text-grey"> {t("popoverTermsAndConditions.text16")}</p>
        <p className="text-grey">{t("popoverTermsAndConditions.text17")}</p>
        <p className="conditions">{t("popoverTermsAndConditions.text18")}</p>
        <p className="text-grey">{t("popoverTermsAndConditions.text19")}</p>
        <p className="text-grey">{t("popoverTermsAndConditions.text20")}</p>
        <p className="text-grey">{t("popoverTermsAndConditions.text21")}</p>
        <p className="text-grey">{t("popoverTermsAndConditions.text22")}</p>
        <p className="text-grey">{t("popoverTermsAndConditions.text23")}</p>
        <p className="conditions">{t("popoverTermsAndConditions.text24")}</p>
        <p className="text-grey">{t("popoverTermsAndConditions.text25")}</p>
        <p className="conditions">{t("popoverTermsAndConditions.text26")}</p>
        <p className="text-grey">{t("popoverTermsAndConditions.text27")}</p>
        <p className="conditions">{t("popoverTermsAndConditions.text28")}</p>
        <p className="text-grey">{t("popoverTermsAndConditions.text29")}</p>
        <p className="conditions">{t("popoverTermsAndConditions.text30")}</p>
        <p className="text-grey">{t("popoverTermsAndConditions.text30a")}</p>
        <p className="text-grey">{t("popoverTermsAndConditions.text31")}</p>
        <p className="text-grey">{t("popoverTermsAndConditions.text32")}</p>
        <p className="text-grey">{t("popoverTermsAndConditions.text33")}</p>
        <p className="text-grey">{t("popoverTermsAndConditions.text34")}</p>
        <p className="text-grey">{t("popoverTermsAndConditions.text35")}</p>
        <p className="text-grey">{t("popoverTermsAndConditions.text36")}</p>
        <p className="text-grey">
          {t("popoverTermsAndConditions.text37")}
          <a href="mailto:info@inkup.io">info@inkup.io</a>
        </p>
        <p className="text-grey">{t("popoverTermsAndConditions.text38")}</p>
        <p className="conditions">{t("popoverTermsAndConditions.text39")}</p>
        <p className="text-grey">{t("popoverTermsAndConditions.text40")}</p>
        <p className="text-grey">{t("popoverTermsAndConditions.text41")}</p>
      </Popover.Body>
    </Popover>
  );

  return (
    <>
      <TermsAndConditions props={props} />
      <div className="divButtonFormTemplate flex-column mt-5" ref={myRef}>
        <div
          className={`divButtonFormTemplate ${
            props.checkTerms ? "bounce" : ""
          }`}
        >
          <Button
            disabled={!props.checkTerms}
            onClick={() => servicesTextMessageResponse(optionButton, props, t)}
            className="botonFormTemplate2"
          >
            {props.contact === 0 || props.user.id_user === 1499 ? (
              <>
                {text1BottomFormChat}
                <IconWhatsApp />
              </>
            ) : (
              <>
                {text3BottomFormChat}
                <img className="iconW" src={iconEmail} alt="icon-w" />
              </>
            )}
          </Button>
        </div>
        <Form.Group className="divTermsFormChat" controlId="formBasicCheckbox">
          <OverlayTrigger
            trigger="click"
            placement="top"
            overlay={popover}
            show={props.showPopover}
            rootClose={true}
          >
            <Button
              className="spamTerminosFormChat2"
              variant="success"
              onClick={() => props.setShowPopover(!props.showPopover)}
            >
              {t("info.showTerms")}
            </Button>
          </OverlayTrigger>
        </Form.Group>
      </div>
    </>
  );
};
