import React from "react";
import "./icon-check-green.css";

export const IconCheckGreen = () => {
  return (
    <div className="divIconCheckGreen">
      <div className="iconCheckGreen">
        <div className="checkGreen"></div>
      </div>
    </div>
  );
};
