import React from "react";
import { IconPlus } from "../../../../Icons/Icon-plus/IconPlus";

export const PButtons = ({ onImageUpload, text, path_media_media_budget }) => {
  return (
    <>
      {(path_media_media_budget?.length === 1 || path_media_media_budget?.length === 2  || path_media_media_budget?.length === 3  || path_media_media_budget?.length === 4 ) && (
        <div className="divPButtonImg">
          <div className="no-data-information">
            <div className="file-upload-coverpage" style={{ border: "none" }}>
              <div className="no-data-container-template noDataContainerTemplate">
                <button className="buttonCPImage">
                  <input
                    className="inputFiles"
                    type="file"
                    onChange={(e) => onImageUpload(e)}
                  />
                  <IconPlus />
                  <p className="text-upload-template urbanist colorTextVar">
                    {text}
                  </p>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
