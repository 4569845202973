import React from "react";
import Spinner from "react-bootstrap/Spinner";
import iconXWhite from "../../../../../../../assets/images/iconXWhite.svg";
import { useEffect } from "react";

export const PImages = ({
  loading,
  imageList,
  deleteImage,
  extensionAllowed,
  loading2,
  setLoading2,
}) => {
  useEffect(() => {
    setLoading2(false);
  }, [imageList]);

  return (
    <>
      {imageList?.map((image, index) => (
        <div key={index} className="divScrollBodypart">
          {extensionAllowed === false &&
          index === imageList.length - 1 &&
          loading &&
          imageList.length !== 0 ? (
            <div className="divSpinnerLoadingImagesFormChat">
              <Spinner animation="border colorSpinner spinnerLoadingImagesFormChat" />
            </div>
          ) : (
            <div className="image-card divImgCardFormTemplate">
              <label className="labelImgFormTemplate labelImgFormTemplate03">
                <img
                  className="imgFormTemplate"
                  src={image}
                  alt="imagePortfolio"
                />
              </label>
              <div className="options btn-portfolio">
                <button
                  className="btn-delete"
                  size="small"
                  onClick={() => deleteImage(index, image.id)}
                  shape="circle"
                >
                  <img src={iconXWhite} alt="x" />
                </button>
              </div>
            </div>
          )}
        </div>
      ))}
      {loading2 && (
        <div className="divScrollBodypart">
          <div className="divSpinnerLoadingImagesFormChat">
            <Spinner animation="border colorSpinner spinnerLoadingImagesFormChat" />
          </div>
        </div>
      )}
    </>
  );
};
