import React from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { IconWhatsApp } from "../Icons/Icon-WhatsApp/IconWhatsApp";
import iconEmail from "../../../../assets/images/Icon-Email.svg";

export const ButtonServices = ({ buttonClass, handle, props }) => {
  const [t] = useTranslation("global");
  let text1BottomFormChat = `${t("SendWhatsAppForm.buttonSendForWhatsApp")}`;
  let text3BottomFormChat = `${t("info.buttonSendEmail")}`;

  return (
    <Button
      onClick={handle}
      className={buttonClass}
      disabled={props.user.check_tattoo === 0 && props.disabledButtonSendEmail}
    >
      {props.user.check_tattoo === 1 ? (
        <>
          {text1BottomFormChat}
          <IconWhatsApp />
        </>
      ) : (
        <>
          {text3BottomFormChat}
          <img className="iconW" src={iconEmail} alt="icon-w" />
        </>
      )}
    </Button>
  );
};
