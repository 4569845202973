import { useTranslation } from "react-i18next";

export const StylesData = () => {
  const [t] = useTranslation("global");

  return [
    {
      id: "fineline",
      text: `${t("styleData.styleFineline")}`,
      value: "Fineline",
      type: "radio",
    },
    {
      id: "geometrio",
      text: `${t("styleData.styleGeometric")}`,
      value: "Geométrico",
      type: "radio",
    },
    {
      id: "micro-realista",
      text: `${t("styleData.styleMicroRealistic")}`,
      value: "Micro Realista",
      type: "radio",
    },
    {
      id: "realismo",
      text: `${t("styleData.styleRealism")}`,
      value: "Realismo",
      type: "radio",
    },
    {
      id: "tradicional",
      text: `${t("styleData.styleTraditional")}`,
      value: "Tradicional",
      type: "radio",
    },
    {
      id: "floral",
      text: `${t("styleData.styleFloral")}`,
      value: "Floral",
      type: "radio",
    },
    {
      id: "anime",
      text: `${t("styleData.styleAnime")}`,
      value: "Anime",
      type: "radio",
    },
    {
      id: "prefiero-asesorarme",
      text: `${t("styleData.stylePreferToConsult")}`,
      value: "Prefiero asesorarme",
      type: "radio",
    },
  ];
};
