import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Form } from "react-bootstrap";
import { MessageLoading } from "../MessageLoading/MessageLoading";
import { DotsComponents } from "../DotsComponent/DotsComponents";
import { SkinToneData } from "./SkinToneData";
import {
  nextComponent,
  setFormComponent,
  setFormComponentInfo,
} from "../../utils/nextComponent";
import { IconArrowRightAnimation } from "../Icons/Icon-arrow-right/IconArrowRightAnimation";

export const SkinTone = ({ block, props }) => {
  const { styles } = props;
  const [t] = useTranslation("global");
  const [messageLoading, setMessageLoading] = useState(true);
  const [skinTone, setSkinTone] = useState("");
  const [dot, setDot] = useState(0);
  const myRef2 = useRef(null);
  let SkinDataArray = SkinToneData();

  useEffect(() => {
    setTimeout(() => {
      setMessageLoading(false);
      props.setShowWriting(false);
      executeScroll();
    }, 1000);
    props.setShowWriting(true);
  }, []);

  const handleColor = async (data, i) => {
    setSkinTone(data.value);
    setDot(i);
    setFormComponent(props, block, { check_skintone: data.value });
    setFormComponentInfo(props, block, { check_skintone: data.imageMessage });
    nextComponent(props, block);
  };

  const executeScroll = () => {
    setTimeout(() => {
      if (myRef2?.current) {
        myRef2.current.scrollIntoView({ behavior: "smooth" });
      }
    }, 100);
  };
  return (
    <>
      {messageLoading === true ? (
        <MessageLoading />
      ) : (
        <>
          <Form.Group id="">
            <div className="divGenericLabelFormChat" ref={myRef2}>
              <Form.Label className="generic01LabelFormChat genericLabelFormChatBubble borderColorLabelVar backgroundColorContainer">
                {t("skinTone.text1")}
                <span className="textBoldFormChat">{t("skinTone.text2")}</span>
                {t("skinTone.text3")}
              </Form.Label>
            </div>
          </Form.Group>
          <IconArrowRightAnimation />
          <Form.Group className="pFC">
            <div
              id="idBodyPartFormChat"
              className="divGenericBodyPartFormChat divUnsetScrollFormChat"
            >
              {SkinDataArray.map((data, index) => (
                <div key={data.id} className="divScrollBodypart">
                  <div key={data.id}>
                    <Form.Check type={data.type} id={`check-api-${data.id}`}>
                      <div
                        // className={
                        //   data?.image
                        //     ? "divBodyPartFormChat"
                        //     : "backgroundCarrousel divBodyPartFormChat"
                        // }
                        className="divBodyPartFormChat"
                        style={{
                          backgroundPosition: "center",
                          backgroundImage: `url(${data.image})`,
                          backgroundColor: data?.image
                            ? null
                            : styles?.color_background_carrousel &&
                              styles?.color_background_carrousel,
                        }}
                      >
                        <Form.Check.Input
                          className="inputRadioFormChat"
                          type={data.type}
                          isValid
                          checked={skinTone === data.value}
                          onChange={() => handleColor(data, index)}
                        />
                        <Form.Check.Label className="divTextDefinitionLabelImage"></Form.Check.Label>
                      </div>
                    </Form.Check>
                  </div>
                </div>
              ))}
            </div>
          </Form.Group>
          <DotsComponents dotNumber={SkinDataArray.length} iDot={dot} />
        </>
      )}
    </>
  );
};
