import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Form } from "react-bootstrap";
import { MessageLoading } from "../MessageLoading/MessageLoading";
import { DotsComponents } from "../DotsComponent/DotsComponents";
import { SizeGeneric, SizeMicro } from "./SizeData";
import {
  nextComponent,
  setFormComponent,
  setFormComponentInfo,
} from "../../utils/nextComponent";
import { IconArrowRightAnimation } from "../Icons/Icon-arrow-right/IconArrowRightAnimation";

export const Size = ({ block, props }) => {
  const { styles, user } = props;
  const [t] = useTranslation("global");
  const [messageLoading, setMessageLoading] = useState(true);
  const [dot, setDot] = useState(0);
  const [size, setSize] = useState("");
  const myRef2 = useRef(null);
  let sizeTattoArrayObjet =
    user.check_size_tattoo === 0 ? SizeGeneric() : SizeMicro();

  useEffect(() => {
    setTimeout(() => {
      setMessageLoading(false);
      props.setShowWriting(false);
      executeScroll();
    }, 1000);
    props.setShowWriting(true);
  }, []);

  const executeScroll = () => {
    setTimeout(() => {
      if (myRef2?.current) {
        myRef2.current.scrollIntoView({ behavior: "smooth" });
      }
    }, 100);
  };

  const handleChange = (value, text, i) => {
    setSize(value);
    setDot(i);
    setFormComponent(props, block, { size: value });
    setFormComponentInfo(props, block, { size: text });
    nextComponent(props, block);
  };
  const scrollContainerRef = useRef(null);

  useEffect(() => {
    let sizeInfoFinally;
    let sizeInfo = sizeTattoArrayObjet.filter(
      (data) => data.value === size && data.textSize
    );
    sizeInfoFinally = sizeInfo[0]?.textSize;
    setFormComponentInfo(props, block, { size: sizeInfoFinally });
  }, [props.language]);

  return (
    <>
      {messageLoading === true ? (
        <MessageLoading />
      ) : (
        <>
          <Form.Group>
            <div className="divGenericLabelFormChat" ref={myRef2}>
              <Form.Label className="generic01LabelFormChat genericLabelFormChatBubble borderColorLabelVar backgroundColorContainer">
                {t("size.text1")}{" "}
                {props.formData?.map((data, index) => (
                  <React.Fragment key={index}>
                    {data?.name_budget}
                  </React.Fragment>
                ))}
                !<br></br>
                {t("size.text2")}
                <span className="textBoldFormChat">{t("size.text3")}</span>
                {t("size.text4")}
              </Form.Label>
            </div>
          </Form.Group>

          <IconArrowRightAnimation />

          <Form.Group className="pFC">
            <div
              id="idSizeFormChat"
              className="divGenericBodyPartFormChat divUnsetScrollFormChat"
            >
              <div className="divScrollBodypart" ref={scrollContainerRef}>
                {sizeTattoArrayObjet.map((data, index) => (
                  <div key={data.id}>
                    <Form.Check
                      type={data.type}
                      id={`check-api-${data.id}
                      
                    `}
                    >
                      <div
                        className={
                          data?.image
                            ? "divBodyPartFormChat"
                            : "divBodyPartFormChat backgroundCarrousel"
                        }
                        style={{
                          backgroundImage: styles
                            ? `linear-gradient(${styles?.color_filter}, ${styles?.color_filter}), url(${data.image})`
                            : `linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url(${data.image})`,
                          borderRadius: "11px",
                        }}
                      >
                        <Form.Check.Input
                          className="inputRadioFormChat"
                          type={data.type}
                          isValid
                          checked={size === data.value}
                          onChange={() =>
                            user.check_cm !== 1
                              ? handleChange(data.value, data.textSize, index)
                              : handleChange(data.value, data.text, index)
                          }
                        />
                        <Form.Check.Label className="divTextDefinitionLabelImage"></Form.Check.Label>
                      </div>
                      <div className="divTextBodyPartPiercing">
                        <p
                          className={
                            props?.styles?.color_text_carousel !== null
                              ? "colorTextVarCarousel m-0"
                              : "colorTextVar m-0"
                          }
                        >
                          {data.text}
                        </p>
                        <p
                          className={
                            props?.styles?.color_text_carousel !== null
                              ? "colorTextVarCarousel m-0"
                              : "colorTextVar m-0"
                          }
                        >
                          {user.check_cm !== 1 &&
                            data.value !== "No lo sé" &&
                            data.textSize}
                        </p>
                      </div>
                    </Form.Check>
                  </div>
                ))}
              </div>
            </div>
          </Form.Group>
          <DotsComponents dotNumber={sizeTattoArrayObjet.length} iDot={dot} />
        </>
      )}
    </>
  );
};
