import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Form } from "react-bootstrap";
import { StylesData } from "./StylesData";
import {
  nextComponent,
  setFormComponent,
  setFormComponentInfo,
} from "../../utils/nextComponent";
import { MessageLoading } from "../MessageLoading/MessageLoading";

export const Styles = ({ block, props }) => {
  const [t] = useTranslation("global");

  const [messageLoading, setMessageLoading] = useState(null);
  const [budget_style, setStyle] = useState("");
  const myRef2 = useRef(null);
  let styleDataArray = StylesData();
  useEffect(() => {
    setTimeout(() => {
      setMessageLoading(false);
      executeScroll();
    }, 1000);
    setMessageLoading(true);
  }, []);

  const handleStyle = (value, text, i) => {
    setStyle(value);
    setFormComponent(props, block, { styles: value });
    setFormComponentInfo(props, block, { styles: text });
    nextComponent(props, block);
  };

  const executeScroll = () => {
    setTimeout(() => {
      if (myRef2?.current) {
        myRef2.current.scrollIntoView({ behavior: "smooth" });
      }
    }, 100);
  };
  return (
    <>
      {messageLoading === true ? (
        <MessageLoading imgLogoInitial={props.initailOrImage} />
      ) : (
        <>
          <Form.Group id="">
            <div className="divGenericLabelFormChat mt-0" ref={myRef2}>
              <Form.Label className="generic01LabelFormChat borderColorLabelVar notMargin genericLabelFormChatBubble backgroundColorContainer">
                <span className="textBoldFormChat">{t("styles.text1")}</span>
                {t("styles.text2")}
              </Form.Label>
            </div>
          </Form.Group>
          <div className="divGenericColumnInputFormChat">
            <div
              id="idCityFormChat"
              className="divGenericInputTattooFormChat div-zindex"
            >
              {styleDataArray?.map((data, index) => (
                <Form.Check
                  key={index}
                  className="borderNoneFormChatSe"
                  type="radio"
                  id={`check-api-${data.id}`}
                >
                  <div className="inputGenericFormChat">
                    <div className="">
                      <div className="divRadioBisFormChat">
                        <Form.Check.Input
                          className="inputRadioFormChat"
                          type="radio"
                          isValid
                          checked={budget_style === data.value}
                          onChange={() =>
                            handleStyle(data.value, data.text, index)
                          }
                        />
                        <Form.Check.Label className="divTextDefinitionLabel">
                          <p className="fisrtCapitalize">{data.text}</p>
                        </Form.Check.Label>
                      </div>
                    </div>
                  </div>
                </Form.Check>
              ))}
            </div>
          </div>
        </>
      )}
    </>
  );
};
