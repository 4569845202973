import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Logo } from "../../../Logo/Logo";
import { Title } from "../../../Title/Title";
import { Description } from "../../../Description/Description";
import { Footer } from "../../../Footer/Footer";
import { Container, Form } from "react-bootstrap";
import { MessageLoading } from "../MessageLoading/MessageLoading";
import { ButtonNewBudget } from "../Button/ButtonNewBudget";

export const NewTattoo = (props) => {
  const { styles, user, medias } = props;
  const [t] = useTranslation("global");
  const [messageLoading, setMessageLoading] = useState(null);
  const [scrollY, setScrollY] = useState(0);
  const [containerHeight, setContainerHeight] = useState(0);

  useEffect(() => {
    let containers = document.getElementsByClassName("chat-header");
    if (containers.length > 0) {
      let container = containers[0];
      let heightContainer = container.offsetHeight;
      setContainerHeight(heightContainer);
    }
  }, []);
  useEffect(() => {
    props.setShowWriting(true);
    setTimeout(() => {
      setMessageLoading(false);
      props.setShowWriting(false);
    }, 1500);
    setMessageLoading(true);
    props.setShowWriting(true);
  }, []);

  return (
    <>
      <Container
        fluid
        translate="no"
        className="containerTemplate containerTemplateBackground urbanist"
      >
        <div className="chat colorTextVar">
          <div className="chat-header colorTextHeader backgroundColorHeader">
            <div className="divHeaderFormChat divHeaderFormChatNone">
              <Logo styles={styles} user={user} medias={medias} />
              <Title user={user} />
            </div>
            <Description user={user} scrollY={scrollY}></Description>
          </div>
          <div
            className="border-color chat-form pdn20 borderColorVar"
            style={{
              marginTop: `${containerHeight}px`,
            }}
          >
            <div className="App">
              <>
                {messageLoading === true ? (
                  <MessageLoading />
                ) : (
                  <>
                    <Form.Group id="">
                      <div className="divGenericLabelColumnFormChat">
                        <Form.Label className="generic01LabelFormChat genericLabelFormChatBubble borderColorLabelVar backgroundColorContainer marginleft16">
                          {t("newTatto.text1")}
                          {t("newTatto.text2")}
                        </Form.Label>
                      </div>
                    </Form.Group>
                    <div className="divButtonFormTemplate">
                      <ButtonNewBudget
                        buttonClass={"botonFormTemplate"}
                        handle={() => window.location.reload()}
                        text={`${t("newTatto.buttonResendRequest")}`}
                      />
                    </div>
                  </>
                )}
              </>
            </div>
            {user.check_watermark === 0 ? (
              <div className="divGenericFooterTemplate" id="screens">
                <Footer id="view" />
              </div>
            ) : null}
          </div>
        </div>
      </Container>
    </>
  );
};
