import React from "react";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export const Title = ({ user, showWriting }) => {
  const [t] = useTranslation("global");
  return (
    <Row>
      <Col>
        <div className="divTitle fontVar">
          <h1 className="fontVar mt-2 mr-3" style={{ marginLeft: "10px" }}>
            {user.studio_name}
          </h1>
          {showWriting ? (
            <p className="text-in-line">{t("title.writing")}...</p>
          ) : (
            <p className="text-in-line">{t("title.inLine")}</p>
          )}
        </div>
      </Col>
    </Row>
  );
};
