import { useTranslation } from "react-i18next";
import imageHead from "../../../../assets/images/image-Head.png";
import imageNeck from "../../../../assets/images/image-Neck.png";
import imageShoulder from "../../../../assets/images/image-Shoulder.png";
import imageArm from "../../../../assets/images/image-Arm.png";
import imageHand from "../../../../assets/images/image-Hand.png";
import imageChest from "../../../../assets/images/image-Chest.png";
import imageBack from "../../../../assets/images/image-Back.png";
import imageCrotch from "../../../../assets/images/image-Crotch.png";
import imageButtock from "../../../../assets/images/image-Buttock.png";
import imageLeg from "../../../../assets/images/image-Leg.png";
import imageFoot from "../../../../assets/images/image-Foot.png";
// import imageIDontKnow from "../../../../assets/images/image-I-Dont-Know.png";
import dontknowImage from "../../../../assets/images/dontknow.png";

export const BodyPartData = () => {
  const [t] = useTranslation("global");

  return [
    {
      id: "no-lo-se-body-part",
      text: `${t("bodyPartData.bodyPartIDoNotKnow")}`,
      value: "No lo sé",
      image: dontknowImage,
      type: "radio",
    },
    {
      id: "pecho",
      text: `${t("bodyPartData.bodyPartChest")}`,
      value: "Pecho",
      image: imageChest,
      type: "radio",
      parts: [
        {
          id: "superior",
          text: `${t("bodyPartData.bodyPartUpper")}`,
          value: "Superior",
          type: "radio",
          classNameBorder: "borderUp",
        },
        {
          id: "esternón",
          text: `${t("bodyPartData.bodyPartSternum")}`,
          value: "Esternón",
          type: "radio",
        },
        {
          id: "abdominales",
          text: `${t("bodyPartData.bodyPartAbdominals")}`,
          value: "Abdominales",
          type: "radio",
        },
        {
          id: "no-lo-se-pecho",
          text: `${t("bodyPartData.bodyPartIDoNotKnow")}`,
          value: "No lo sé",
          type: "radio",
          classNameBorder: "borderBottom",
        },
      ],
    },
    {
      id: "brazo",
      text: `${t("bodyPartData.bodyPartArm")}`,
      value: "Brazo",
      image: imageArm,
      type: "radio",
      parts: [
        {
          id: "manga",
          text: `${t("bodyPartData.bodyPartSleeve")}`,
          value: "Manga",
          type: "radio",
          classNameBorder: "borderUp",
        },
        {
          id: "media-manga-baja",
          text: `${t("bodyPartData.bodyParttHalfSleeve")}`,
          value: "Media manga (muñeca a codo)",
          type: "radio",
          subParts: [
            {
              id: "interior",
              text: `${t("bodyPartData.bodyParttHalfSleeveInside")}`,
              value: "Interior",
              type: "radio",
            },
            {
              id: "exterior",
              text: `${t("bodyPartData.bodyParttHalfSleeveOuter")}`,
              value: "Exterior",
              type: "radio",
            },
            {
              id: "Interior-y-exterior",
              text: `${t("bodyPartData.bodyParttHalfSleeveBoth")}`,
              value: "Interior y exterior",
              type: "radio",
            },
          ],
        },
        {
          id: "media-manga-alta",
          text: `${t("bodyPartData.bodyParttHalfSleeve2")}`,
          value: "Media manga (codo a hombro)",
          type: "radio",
          subParts: [
            {
              id: "interior",
              text: `${t("bodyPartData.bodyParttHalfSleeveInside")}`,
              value: "Interior",
              type: "radio",
            },
            {
              id: "exterior",
              text: `${t("bodyPartData.bodyParttHalfSleeveOuter")}`,
              value: "Exterior",
              type: "radio",
            },
            {
              id: "Interior-y-exterior",
              text: `${t("bodyPartData.bodyParttHalfSleeveBoth")}`,
              value: "Interior y exterior",
              type: "radio",
            },
          ],
        },
        {
          id: "biceps",
          text: `${t("bodyPartData.bodyPartBiceps")}`,
          value: "Bíceps",
          type: "radio",
        },
        {
          id: "triceps",
          text: `${t("bodyPartData.bodyPartTriceps")}`,
          value: "Tríceps",
          type: "radio",
        },
        {
          id: "codo",
          text: `${t("bodyPartData.bodyPartElbow")}`,
          value: "Codo",
          type: "radio",
        },
        {
          id: "antebrazo",
          text: `${t("bodyPartData.bodyPartForearm")}`,
          value: "Antebrazo",
          type: "radio",
        },
        {
          id: "muñeca",
          text: `${t("bodyPartData.bodyPartWrist")}`,
          value: "Muñeca",
          type: "radio",
        },
        {
          id: "no-lo-se-brazo",
          text: `${t("bodyPartData.bodyPartIDoNotKnow")}`,
          value: "No lo sé",
          type: "radio",
          classNameBorder: "borderBottom",
        },
      ],
    },
    {
      id: "hombro",
      text: `${t("bodyPartData.bodyPartShoulder")}`,
      value: "Hombro",
      image: imageShoulder,
      type: "radio",
      parts: [
        {
          id: "lateral-cuello",
          text: `${t("bodyPartData.bodyPartLateral")}`,
          value: "Lateral",
          type: "radio",
          classNameBorder: "borderUp",
        },
        {
          id: "detrás",
          text: `${t("bodyPartData.bodyPartBackShoulder")}`,
          value: "Detrás",
          type: "radio",
        },
        {
          id: "delante",
          text: `${t("bodyPartData.bodyPartFrontShoulder")}`,
          value: "Delante",
          type: "radio",
        },
        {
          id: "no-lo-se-hombro",
          text: `${t("bodyPartData.bodyPartIDoNotKnow")}`,
          value: "No lo sé",
          type: "radio",
          classNameBorder: "borderBottom",
        },
      ],
    },
    {
      id: "espalda",
      text: `${t("bodyPartData.bodyPartBack")}`,
      value: "Espalda",
      image: imageBack,
      type: "radio",
      parts: [
        {
          id: "columna",
          text: `${t("bodyPartData.bodyPartColumn")}`,
          value: "Columna",
          type: "radio",
        },
        {
          id: "costillas",
          text: `${t("bodyPartData.bodyPartRibs")}`,
          value: "Costillas",
          type: "radio",
        },
        {
          id: "lumbar",
          text: `${t("bodyPartData.bodyPartLumbar")}`,
          value: "Lumbar",
          type: "radio",
        },
        {
          id: "espalda-completa",
          text: `${t("bodyPartData.bodyPartFullBack")}`,
          value: "Espalda completa",
          type: "radio",
        },
        {
          id: "no-lo-se-espalda",
          text: `${t("bodyPartData.bodyPartIDoNotKnow")}`,
          value: "No lo sé",
          type: "radio",
          classNameBorder: "borderBottom",
        },
      ],
    },
    {
      id: "pierna",
      text: `${t("bodyPartData.bodyPartLeg")}`,
      value: "Pierna",
      image: imageLeg,
      type: "radio",
      parts: [
        {
          id: "muslo",
          text: `${t("bodyPartData.bodyPartThigh")}`,
          value: "Muslo",
          type: "radio",
          classNameBorder: "borderUp",
        },
        {
          id: "Contramuslo",
          text: `${t("bodyPartData.bodyPartCounterThigh")}`,
          value: "Contramuslo",
          type: "radio",
        },
        {
          id: "rodilla",
          text: `${t("bodyPartData.bodyPartKnee")}`,
          value: "Rodilla",
          type: "radio",
        },
        {
          id: "espinilla",
          text: `${t("bodyPartData.bodyPartShin")}`,
          value: "Espinilla",
          type: "radio",
        },
        {
          id: "gemelo",
          text: `${t("bodyPartData.bodyPartCalf")}`,
          value: "Gemelo",
          type: "radio",
        },
        {
          id: "no-lo-se-pierna",
          text: `${t("bodyPartData.bodyPartIDoNotKnow")}`,
          value: "No lo sé",
          type: "radio",
          classNameBorder: "borderBottom",
        },
      ],
    },
    {
      id: "mano",
      text: `${t("bodyPartData.bodyPartHand")}`,
      value: "Mano",
      image: imageHand,
      type: "radio",
      parts: [
        {
          id: "dorso",
          text: `${t("bodyPartData.bodyPartBackHand")}`,
          value: "Dorso",
          type: "radio",
          classNameBorder: "borderUp",
        },
        {
          id: "palma",
          text: `${t("bodyPartData.bodyPartPalm")}`,
          value: "Palma",
          type: "radio",
        },
        {
          id: "dedos",
          text: `${t("bodyPartData.bodyPartFingers")}`,
          value: "Dedos",
          type: "radio",
        },
        {
          id: "no-lo-se-mano",
          text: `${t("bodyPartData.bodyPartIDoNotKnow")}`,
          value: "No lo sé",
          type: "radio",
          classNameBorder: "borderBottom",
        },
      ],
    },
    {
      id: "pie",
      text: `${t("bodyPartData.bodyPartFoot")}`,
      value: "Pie",
      image: imageFoot,
      type: "radio",
      parts: [
        {
          id: "tobillo",
          text: `${t("bodyPartData.bodyPartAnkle")}`,
          value: "Tobillo",
          type: "radio",
          classNameBorder: "borderUp",
        },
        {
          id: "empeine",
          text: `${t("bodyPartData.bodyPartInstep")}`,
          value: "Empeine",
          type: "radio",
        },
        {
          id: "no-lo-se-pie",
          text: `${t("bodyPartData.bodyPartIDoNotKnow")}`,
          value: "No lo sé",
          type: "radio",
          classNameBorder: "borderBottom",
        },
      ],
    },
    {
      id: "glúteo",
      text: `${t("bodyPartData.bodyPartButtock")}`,
      value: "Glúteo",
      image: imageButtock,
      type: "radio",
    },
    {
      id: "ingle",
      text: `${t("bodyPartData.bodyPartGroin")}`,
      value: "Ingle",
      image: imageCrotch,
      type: "radio",
      classNameBorder: "borderUp",
    },
    {
      id: "cuello",
      text: `${t("bodyPartData.bodyPartNeck")}`,
      value: "Cuello",
      image: imageNeck,
      type: "radio",
      parts: [
        {
          id: "lateral",
          text: `${t("bodyPartData.bodyPartLateral")}`,
          value: "Lateral",
          type: "radio",
          classNameBorder: "borderUp",
        },
        {
          id: "garganta",
          text: `${t("bodyPartData.bodyPartThroat")}`,
          value: "Garganta",
          type: "radio",
        },
        {
          id: "nuca",
          text: `${t("bodyPartData.bodyPartNape")}`,
          value: "Nuca",
          type: "radio",
        },
        {
          id: "no-lo-se-cuello",
          text: `${t("bodyPartData.bodyPartIDoNotKnow")}`,
          value: "No lo sé",
          type: "radio",
          classNameBorder: "borderBottom",
        },
      ],
    },
    {
      id: "cabeza",
      text: `${t("bodyPartData.bodyPartHead")}`,
      value: "Cabeza",
      image: imageHead,
      type: "radio",
      parts: [
        {
          id: "frente",
          text: `${t("bodyPartData.bodyPartFront")}`,
          value: "Frente",
          type: "radio",
          classNameBorder: "borderUp",
        },
        {
          id: "barbilla",
          text: `${t("bodyPartData.bodyPartChin")}`,
          value: "Barbilla",
          type: "radio",
        },
        {
          id: "oreja",
          text: `${t("bodyPartData.bodyPartEar")}`,
          value: "Oreja",
          type: "radio",
        },
        {
          id: "boca",
          text: `${t("bodyPartData.bodyPartMouth")}`,
          value: "Boca",
          type: "radio",
        },
        {
          id: "nariz",
          text: `${t("bodyPartData.bodyPartNose")}`,
          value: "Nariz",
          type: "radio",
        },
        {
          id: "no-lo-se-cabeza",
          text: `${t("bodyPartData.bodyPartIDoNotKnow")}`,
          value: "No lo sé",
          type: "radio",
          classNameBorder: "borderBottom",
        },
      ],
    },
  ];
};
