import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Form } from "react-bootstrap";
import { MessageLoading } from "../MessageLoading/MessageLoading";
import {
  nextComponent,
  setFormComponent,
  setFormComponentInfo,
  backComponent,
  clearFormData,
} from "../../utils/nextComponent";
import { getUniqueCountries, filterByCountry, getCountryName } from "./CountryClassifier";


export const City = ({ block, props }) => {
  const [t] = useTranslation("global");
  const [messageLoading, setMessageLoading] = useState(true);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const myRef2 = useRef(null);
  let language = props.language;

  useEffect(() => {
    setTimeout(() => {
      setMessageLoading(false);
      props.setShowWriting(false);
      executeScroll();
    }, 1000);
    props.setShowWriting(true);
    props.setCity("");
  }, []);

  const executeScroll = () => {
    setTimeout(() => {
      if (myRef2?.current) {
        myRef2.current.scrollIntoView({ behavior: "smooth" });
      }
    }, 100);
  };

  const handleChange = (data) => {
    setTimeout(() => {
      props.setShowWriting(false);
    }, 1000);
    props.setShowWriting(true);
    // }

    props.setCity(data.name);
    if (props.user.id_user === 1499) {
      props.setContact(null);
    } else {
      props.setContact(false);
    }
    // setEmailOrWa(true);
    
    setFormComponent(props, block, {
      check_city: data.name,
    });
    setFormComponentInfo(props, block, {
      check_city: data.name,
    });
    if (props.arrayComponentServices.length !== 0) {
      props.setCheckTermsAnotherQuery(false);
    }

    if (props.typeArray === 0) {
      backComponent(props, block);
      nextComponent(props, block);
      props.setContact(null);
      props.setPhoneCity(data.phone_city);
      props.setEmailCity(data.email_city);
      if (props.arrayComponentServices.length === 0) {
        nextComponent(props, block);
      }
    } else {
      backComponent(props, block, 1);
      clearFormData(props, block);

      nextComponent(props, block, 1);
      // }
      props.setEmailOrPhone(true);
      props.setPhoneCity(data.phone_city);
      props.setEmailCity(data.email_city);
    }
    executeScroll();
  };

  const uniqueCountries = getUniqueCountries(props.cities);

  const handleCountryChange = (country) => {
    setSelectedCountry(country);
    executeScroll();
  };

  const filteredCities = selectedCountry ? filterByCountry(selectedCountry, props.cities) : [];

  return (
    <>
      {messageLoading === true ? (
        <MessageLoading />
      ) : (
        <>
          <Form.Group id="">
            <div className="divGenericLabelFormChat" ref={myRef2}>
              <Form.Label className="generic01LabelFormChat genericLabelFormChatBubble borderColorLabelVar backgroundColorContainer notMargin">
                <span className="textBoldFormChat">{t("city.text1")}</span>
                {t("city.text2")}
                {props.formData?.map((data, index) => (
                  <React.Fragment key={index}>
                    {data?.name_budget}
                  </React.Fragment>
                ))}
                {language === "en" ? "" : "?"}
                {/* {"?"} */}
              </Form.Label>
            </div>
          </Form.Group>
          <>
          {props.user.id_user === 1435 ? (
            <div className="divGenericColumnInputFormChat">
            <div className="customCitySelector">
              
          
              <div
                id="idCountryFormChat"
                className="divGenericInputTattooFormChat div-zindex customCountryFormChat"
              >
                {uniqueCountries.map((country, index) => (
                  <Form.Check
                    key={index}
                    className="borderNoneFormChatSe"
                    type="radio"
                    id={`check-api-${country}`}
                  >
                    <div
                      className="inputGenericFormChat"
                      onClick={() => handleCountryChange(country)}
                    >
                      <div className="">
                        <div className="divRadioBisFormChat">
                          <Form.Check.Input
                            className="inputRadioFormChat"
                            type="radio"
                            isValid
                            readOnly
                            checked={selectedCountry === country}
                          />
                          <Form.Check.Label className="divTextDefinitionLabel">
                            <p className="fisrtCapitalize">{getCountryName(country, language)}</p>
                          </Form.Check.Label>
                        </div>
                      </div>
                    </div>
                  </Form.Check>
                ))}
              </div>

              {selectedCountry && (
                <div
                  id="idCityFormChat"
                  className="divGenericInputTattooFormChat div-zindex customCityFormChat"
                >
                  {filteredCities?.map((data, index) => (
                    <Form.Check
                      key={index}
                      className="borderNoneFormChatSe"
                      type="radio"
                      id={`check-api-${data.id_city}`}
                    >
                      <div
                        className="inputGenericFormChat"
                        onClick={() => handleChange(data)}
                      >
                        <div className="">
                          <div className="divRadioBisFormChat">
                            <Form.Check.Input
                              className="inputRadioFormChat"
                              type="radio"
                              isValid
                              readOnly
                              checked={props.city === data.name}
                            />
                            <Form.Check.Label className="divTextDefinitionLabel">
                              <p className="fisrtCapitalize">{data.name}</p>
                            </Form.Check.Label>
                          </div>
                        </div>
                      </div>
                    </Form.Check>
                  ))}
                </div>
              )}
            </div>
          </div>
            
          ):(
            <div className="divGenericColumnInputFormChat">
            <div
              id="idCityFormChat"
              className="divGenericInputTattooFormChat div-zindex"
            >
              {props.cities?.map((data, index) => (
                <Form.Check
                  key={index}
                  className="borderNoneFormChatSe"
                  type="radio"
                  id={`check-api-${data.id_city}`}
                >
                  <div
                    className="inputGenericFormChat"
                    onClick={() => handleChange(data)}
                  >
                    <div className="">
                      <div className="divRadioBisFormChat">
                        <Form.Check.Input
                          className="inputRadioFormChat"
                          type="radio"
                          isValid
                          readOnly
                          checked={props.city === data.name}
                        // onChange={() => handleChange(data)}
                        />
                        <Form.Check.Label className="divTextDefinitionLabel">
                          <p className="fisrtCapitalize">{data.name}</p>
                        </Form.Check.Label>
                      </div>
                    </div>
                  </div>
                </Form.Check>
              ))}
            </div>
          </div>
        )}
          
          {/* {props.emailOrPhone && emailOrWa && emailOrCity && (
            <EmailOrWhatsapp block={block} props={props} />
          )} */}
          </>
        </>
      )}
    </>
  );
};
