import { useTranslation } from "react-i18next";
import arm from "../../../../assets/images/image-Arm.png";

export const TimeData = () => {
  const [t] = useTranslation("global");
  return [
    {
      id: "mañanas",
      value: "Mañanas",
      text: `${t("timeData.cityMorning")}`,
      image: arm,
      type: "radio",
      classNameBorder: "borderUp",
    },
    {
      id: "tardes",
      value: "Tardes",
      text: `${t("timeData.cityAfternoon")}`,
      image: arm,
      type: "radio",
    },
    {
      id: "ambos",
      value: "Ambos",
      text: `${t("timeData.cityBoth")}`,
      image: arm,
      type: "radio",
      classNameBorder: "borderBottom",
    },
  ];
};
