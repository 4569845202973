import firebase from "firebase/compat/app";
import "firebase/compat/storage";
import "firebase/compat/firestore";
const projectId = process.env.REACT_APP_FB_projectId;
const appId = process.env.REACT_APP_FB_appId;
const storageBucket = process.env.REACT_APP_FB_storageBucket;
const locationId = process.env.REACT_APP_FB_apiKey;
const apiKey = process.env.REACT_APP_FB_authDomain;
const authDomain = process.env.REACT_APP_FB_messagingSenderId;
const messagingSenderId = process.env.REACT_APP_FB_projectId;
const measurementId = process.env.REACT_APP_FB_measurementId;

export const appFirebase = firebase.initializeApp({
  projectId,
  appId,
  storageBucket,
  locationId,
  apiKey,
  authDomain,
  messagingSenderId,
  measurementId,
});
