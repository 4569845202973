import { useTranslation } from "react-i18next";
import arm from "../../../../assets/images/image-Arm.png";

export const OldClientData = () => {
  const [t] = useTranslation("global");
  return [
    {
      id: "si",
      value: 1,
      text: `${t("piercingpreviouslyData.piercingYes")}`,
      image: arm,
      type: "radio",
      classNameBorder: "borderUp",
    },
    {
      id: "no",
      value: '0',
      text: `${t("piercingpreviouslyData.piercingNo")}`,
      image: arm,
      type: "radio",
      classNameBorder: "borderUp",

    },
  ];
};