import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Form } from "react-bootstrap";
import { MessageLoading } from "../MessageLoading/MessageLoading";
import { MultiImage } from "./MultiImage/MultiImage";

export const Images = ({ block, props }) => {
  const [messageLoading, setMessageLoading] = useState(true);
  const [extensionAllowed, setExtensionAllowed] = useState(null);
  const [filesAllowed, setFilesAllowed] = useState(null);
  const [t] = useTranslation("global");
  const myRef2 = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      setMessageLoading(false);
    }, 1000);
    // setMessageLoading(true);

    setTimeout(() => {
      setMessageLoading(null);
      props.setShowWriting(false);
      executeScroll();
    }, 3000);
    props.setShowWriting(true);
  }, []);

  const executeScroll = () => {
    setTimeout(() => {
      if (myRef2?.current) {
        myRef2.current.scrollIntoView({ behavior: "smooth" });
      }
    }, 100);
  };

  return (
    <>
      {messageLoading === true && <MessageLoading />}
      {messageLoading === false || messageLoading === null ? (
        <Form.Group id="" className="mt-4">
          <div
            className="divGenericLabelFormChat marginNoneLabelFormChat marginBottom8px"
            ref={myRef2}
          >
            <Form.Label className="genericLabelFormChatBubble borderColorLabelVar backgroundColorContainer">
              {t("images.text1")}{" "}
              {props.formData?.map((data, index) => (
                <React.Fragment key={index}>{data?.name_budget}</React.Fragment>
              ))}
              !<br></br>
              {t("images.text2")}
              <span className="textBoldFormChat">
                {t("images.text3")}
                {(props?.user?.id_user === 1509 || props?.user?.id_user === 1142) && t("images.text3Stevo")}
              </span>
            </Form.Label>
          </div>
        </Form.Group>
      ) : null}
      {messageLoading === false && <MessageLoading />}
      {messageLoading === null ? (
        <Form.Group id="" className="mt-2">
          <div
            className="divGenericLabelFormChat marginNoneLabelFormChat"
            ref={myRef2}
          >
            <Form.Label className="generic01LabelFormChat genericLabelFormChatBubble borderColorLabelVar backgroundColorContainer">
              {t("images.text4")}
            </Form.Label>
          </div>
        </Form.Group>
      ) : null}
      {messageLoading === null && (
        <div className="pFC">
          <div className="divGenericBodyPartFormChat divUnsetScrollFormChat">
            <MultiImage
              props={props}
              block={block}
              extensionAllowed={extensionAllowed}
              setExtensionAllowed={setExtensionAllowed}
              filesAllowed={filesAllowed}
              setFilesAllowed={setFilesAllowed}
            />
          </div>
        </div>
      )}
      {extensionAllowed === true && (
        <div className="div-text-error">
          <p>{t("images.messageError")}</p>
        </div>
      )}
      {filesAllowed === true && (
        <div className="div-text-error">
          <p>{t("images.filesError")}</p>
        </div>
      )}
    </>
  );
};
