import React from "react";

export const DotsComponents = ({ dotNumber, iDot }) => {
  return (
    <div className="divSelectProgress margin20px">
      {[...Array(dotNumber)].map((x, i) => (
        <div key={i} className={i === iDot ? "dotLight" : "dotDark"}></div>
      ))}
    </div>
  );
};
