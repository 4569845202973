const ROOT_URL_IMG = process.env.REACT_APP_IMG_URL;
export const aplyClass = async (styles) => {
  const urlImage = ROOT_URL_IMG + styles?.bg_image;
  const style = document.documentElement.style;
  style.setProperty("--bg_button", styles?.bg_button);
  style.setProperty("--bg_input", styles?.bg_input);
  style.setProperty("--color_border", styles?.color_border);
  style.setProperty("--color_dot_dark", styles?.color_dot_dark);
  style.setProperty("--color_dot_light", styles?.color_dot_light);
  style.setProperty("--color_filter", styles?.color_filter);
  style.setProperty("--color_input_span", styles?.color_input_span);
  style.setProperty("--color_border_assistant", styles?.color_border_assistant);
  style.setProperty("--color_text", styles?.color_text);
  style.setProperty("--color_text_header", styles?.color_text);
  style.setProperty(
    "--color_background_carrousel",
    styles?.color_background_carrousel
  );
  style.setProperty("--color_text_carrousel", styles?.color_text_carrousel);
  style.setProperty("--color_text_button", styles?.color_text_button);
  style.setProperty("--color_text_image", styles?.color_text_image);
  style.setProperty("--typography", styles?.typography);
  style.setProperty("--typography_description", styles?.typography_description);
  style.setProperty("--bg_color_header", styles?.bg_color_header);
  style.setProperty("--bg_color_container", styles?.bg_color_container);
  style.setProperty("--bg_color_page_loading", styles?.bg_color_page_loading);

  if (styles?.bg_image?.length >= 0) {
    style.setProperty("--bg_image", `url(${urlImage})`);
    style.setProperty("--bg_color_assistant", "transparent");
  } else {
    style.setProperty("--bg_color", styles?.bg_color);
    style.setProperty("--bg_color_assistant", styles?.bg_color_assistant);
  }
};
