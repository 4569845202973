import React, { useState } from "react";
import { IconDanger } from "../../../../Icons/icon-danger/IconDanger";
import { nextComponent } from "../../../../../utils/nextComponent";
export const PButtonNotImages = ({
  props,
  block,
  setExtensionAllowed,
  text,
}) => {
  const [changeClassName, setChangeClassName] = useState(true);

  const handleChange = () => {
    setChangeClassName(false);
    nextComponent(props, block);
    setExtensionAllowed(false);
  };

  return (
    <div className="divBodyPartFormChat">
      <div className="file-upload-coverpage" style={{ border: "none" }}>
        <div
          className={
            changeClassName === true
              ? "no-data-container-template"
              : "no-data-container-template-border"
          }
          onClick={handleChange}
        >
          <button className="buttonCPImage">
            <IconDanger />
            <p className="colorTextVar"> {text}</p>
          </button>
        </div>
      </div>
    </div>
  );
};
