//    INFO convertimos FormChatComponents en una funcion para poder recibir un parametro desde TemplateRoute que es donde la importamos. para con este parametro, poder hacer el condicional en la clave component, dentro del objeto que monta services.
export const DataAll = (compare) => {
  return [
    {
      _uid: "BUY6Drn1e1",
      component: "welcome",
      headline: "Welcome",
      conditional: true,
    },
    {
      _uid: "BUY6Drn1e7",
      component: "body_part",
      headline: "body_part",
      conditional: false,
    },
    {
      _uid: "BUY6Drn2e7",
      component: "old_client",
      headline: "old_client",
      conditional: false,
    },
    {
      _uid: "BUY6Drn2e6",
      component: "type_jewelry",
      headline: "type_jewelry",
      conditional: false,
    },
    {
      _uid: "BUY6Drn2e4",
      component:
        compare === "check_query"
          ? "check_query"
          : compare === "check_laser"
          ? "check_laser"
          : compare === "check_micro"
          ? "check_micro"
          : compare === "check_piercing"
          ? "check_piercing"
          : compare === "check_barber"
          ? "check_barber"
          : compare === "check_microblading"
          ? "check_microblading"
          : compare === "check_gem_tooth"
          ? "check_gem_tooth"
          : compare === "check_academy"
          ? "check_academy"
          : "",
      headline: "service" && "check_laser",
      conditional: false,
    },
    {
      _uid: "BUY6Drn2e8",
      component: "finish_text",
      headline: "finish_text",
      conditional: false,
    },
    {
      _uid: "BUY6Drn2e3",
      component: "check_employeds",
      headline: "check_employeds",
      conditional: false,
    },
    {
      _uid: "BUY6Drn1e2",
      component: "name_budget",
      headline: "name_budget",
      conditional: false,
    },
    {
      _uid: "BUY6Drn2e11",
      component: "date_budget",
      headline: "date_budget",
      conditional: false,
    },
    {
      _uid: "BUY6Drn1e5",
      component: "size",
      headline: "size",
      conditional: false,
    },
    {
      _uid: "BUY6Drn1e6",
      component: "budget_color",
      headline: "budget_color",
      conditional: false,
    },
    {
      _uid: "BUY6Drn2e7e8",
      component: "styles",
      headline: "styles",
      conditional: false,
    },
    {
      _uid: "BUY6Drn1e9",
      component: "images",
      headline: "images",
      conditional: false,
    },
    {
      _uid: "BUY6Drn3e2",
      component: "images_micro",
      headline: "images_micro",
      conditional: false,
    },
    {
      _uid: "BUY6Drn1e8",
      component: "description",
      headline: "description",
      conditional: false,
    },
    {
      _uid: "BUY6Drn2e10",
      component: "check_skintone",
      headline: "check_skintone",
      conditional: false,
    },
    {
      _uid: "BUY6Drn2e2",
      component: "time_availability",
      headline: "time_availability",
      conditional: false,
    },
    {
      _uid: "BUY6Drn2e1",
      component: "check_city",
      headline: "check_city",
      conditional: false,
    },
    {
      _uid: "BUY6Drn1e3",
      component: "email_budget",
      headline: "email_budget",
      conditional: false,
    },

    {
      _uid: "BUY6Drn1e4",
      component: "phone_budget",
      headline: "phone_budget",
      conditional: false,
    },

    {
      _uid: "BUY6Drn2e9",
      component: "email_phone_budget",
      headline: "email_phone_budget",
      conditional: false,
    },

    {
      _uid: "BUY6Drn2e5",
      component: "info",
      headline: "info",
      conditional: false,
    },

    {
      _uid: "BUY6Drn3e1",
      component: "button_other_services",
      headline: "button_other_services",
      conditional: false,
    },

    {
      _uid: "BUY6Drn3e2",
      component: "email_or_whatsapp",
      headline: "email_or_whatsapp",
      conditional: false,
    },
  ];
};
