import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { PImages } from "./components/PImage/PImages";
import { PButtonUploadImage } from "./components/PButtons/PButtonUploadImage";
import { PButtonNotImages } from "./components/PButtons/PButtonNotImages";
import { PButtons } from "./components/PButtons/PButtons";
import { appFirebase } from "../../../../../fb";
import {
  nextComponent,
  saveChanges,
  backComponent,
  setFormComponent,
  clearFormData,
  setFormComponentInfo,
} from "../../../utils/nextComponent";

export const MultiImage = ({
  props,
  block,
  extensionAllowed,
  setExtensionAllowed,
  filesAllowed,
  setFilesAllowed,
}) => {
  const [t] = useTranslation("global");
  const [path_media_media_budget, setImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loading2, setLoading2] = useState(false);
  const psButtonRef = useRef(null);

  const description = props?.formData?.filter((description) => {
    return description["description"];
  });

  const existDescription = props?.data?.some((element) => {
    return element.component === "description";
  });

  const deleteImage = async (indexArray, id) => {
    const updatedItems = path_media_media_budget.filter(
      (image, index) => indexArray !== index
    );
    setImages(updatedItems);
    setExtensionAllowed(null);
    if (
      updatedItems.length === 0 &&
      (description[0]?.description.length === undefined ||
        description[0]?.description.length <= 3)
    ) {
      props.setNotImages(true);
      clearFormData(props, block, { images: path_media_media_budget });
      backComponent(props, block);
      setTimeout(() => {
        props.setNotImages(false);
      }, 1000);
    } else {
      setFormComponent(props, block, {
        path_media_media_budget: [...updatedItems],
      });
      setFormComponentInfo(props, block, {
        path_media_media_budget: [...updatedItems],
      });
      saveChanges(props, block);
    }
  };

  const fileHandler = async (e) => {
    setLoading2(true);
    const files = e.target.files;
    const uploadedImageUrls = [];
    if (files.length > 5) {
      setFilesAllowed(true);
      setLoading2(false);

      return;
    }
    for (let i = 0; i < files.length; i++) {
      const archivo = files[i];
      if (
        archivo.type === "image/svg+xml" ||
        archivo.type === "image/jpg" ||
        archivo.type === "image/jpeg" ||
        archivo.type === "image/png"
      ) {
        setExtensionAllowed(false);
        setFilesAllowed(false);
        let date = Date.now();
        let archivoName = archivo;
        archivoName = props.user.id_user.toString() + date;
        const storageRef = appFirebase.storage().ref();
        const archivoPath = storageRef.child(archivoName);
        await archivoPath.put(archivo);
        const enlaceUrl = await archivoPath.getDownloadURL();
        uploadedImageUrls.push(enlaceUrl.split("&token")[0]);
        setLoading(true);
        setTimeout(() => {
          setLoading(false);
          psButtonRef.current.scrollIntoView({ behavior: "smooth" });
        }, 50);
      } else {
        setExtensionAllowed(true);
      }
    }
    setFormComponent(props, block, {
      images: [...path_media_media_budget, ...uploadedImageUrls],
    });
    setFormComponentInfo(props, block, {
      images: [...path_media_media_budget, ...uploadedImageUrls],
    });
    if (description[0]?.description.length <= 3) {
      backComponent(props, block);
    }
    nextComponent(props, block);
    setImages((images) => [...images, ...uploadedImageUrls]);
  };

  return (
    <>
      <PImages
        loading={loading}
        imageList={path_media_media_budget}
        deleteImage={deleteImage}
        extensionAllowed={extensionAllowed}
        filesAllowed={filesAllowed}
        loading2={loading2}
        setLoading2={setLoading2}
      />
      {path_media_media_budget.length === 0 && (
        <div className="divGenericBodyPartFormChat">
          <div className="divScrollBodypart">
            <PButtonUploadImage
              id="idButtonAddImagesFormChat"
              onImageUpload={fileHandler}
              text={t("multiImage.addImage")}
              maxImage={t("multiImage.maxImage")}
            />
            {existDescription && (
              <PButtonNotImages
                id="idButtonNotImagesFormChat"
                text={t("multiImage.notImages")}
                props={props}
                block={block}
                setExtensionAllowed={setExtensionAllowed}
              />
            )}
          </div>
        </div>
      )}
      {path_media_media_budget?.length !== 0 && (
        <div ref={psButtonRef}>
          <PButtons
            id="idButtonMoreImagesFormChat"
            onImageUpload={fileHandler}
            text={t("multiImage.anotherImage")}
            path_media_media_budget={path_media_media_budget}
          />
        </div>
      )}
    </>
  );
};
