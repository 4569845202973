import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Row, Col, Button } from "react-bootstrap";
import imageLogoType from "../../assets/images/Logotype.svg";

export const Footer = ({ props }) => {
  const [t] = useTranslation("global");
  const domainName = props?.user?.domain_name
  return (
    <>
      <Row>
        <Col>
          <div className="divFooterForChat">
            <a
              href={`//app.inkup.io/signup/?utm_source=my.inkup&utm_medium=profile&utm_campaign=footer_logo_cta&utm_content=${domainName}`}
              target="_blank"
              rel="noopener noreferrer"
              className="footer-link"
            >
              <Button className="button-footer">{t("footer.text")}</Button>
            
              <div className="divImgLogoType">
                <img src={imageLogoType} alt="inkup.io" style={{ width: 'auto', maxHeight: '40px' }} ></img>
              </div>
              </a>
          </div>
        </Col>
      </Row>
    </>
  );
};
