import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Form } from "react-bootstrap";
import { MessageLoading } from "../MessageLoading/MessageLoading";
import { ServicesData } from "./ServicesData";
import { ButtonServices } from "../Button/ButtonServices";
import { AnotherQuery } from "../AnotherQuery/AnotherQuery";
import { servicesTextMessageResponse } from "./servicesTextMessageResponse";

import {
  nextComponent,
  backComponent,
  clearFormData,
  setFormComponent,
} from "../../utils/nextComponent";
import { Email } from "../Email/Email";

export const Services = ({ block, props }) => {
 

  const [t] = useTranslation("global");
  const [messageLoading, setMessageLoading] = useState(null);
  const [messageLoading2, setMessageLoading2] = useState(null);
  const [buttonWhatsapp, setButtonWhatsapp] = useState(null);
  const [optionButton, setOptionButton] = useState(null);
  const [chekedServiceTattoo, setChekedServiceTattoo] = useState(null);
  const [disableButtom, setdisableButtom] = useState(false);
  const [showEmail, setShowEmail] = useState(false);
  const myRef = useRef(null);
  const myRef1 = useRef(null);

  let serviceTattoArrayObjet = ServicesData(
    props.user.check_query,
    props.user.check_laser,
    props.user.check_micro,
    props.user.check_piercing,
    props.user.check_barber,
    props.user.check_microblading,
    props.user.check_gem_tooth,
    props.user.id_rol,
    props.user.check_academy
  );
   let showServiceArrayObjet = serviceTattoArrayObjet.filter(
    (item) => item.checkData === true
  );

  useEffect(() => {
    if (block.conditional) {
      props.setShowWriting(true);
      setMessageLoading(true);
      setTimeout(() => {
        setMessageLoading(false);
        props.setShowWriting(false);
        executeScroll1();
      }, 1000);
    }
  }, []);

  useEffect(() => {
    executeScroll();
  }, [buttonWhatsapp, optionButton]);

  ////// SCROLL PARA CUANDO APARECE SERVICIOS//////
  const executeScroll1 = () => {
    setTimeout(() => {
      const element = myRef1?.current;
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
        const elementRect = element.getBoundingClientRect();
        const absoluteElementTop = elementRect.top + window.scrollY;
        const middle = absoluteElementTop - window.innerHeight / 9;
        window.scrollTo({ top: middle, behavior: "smooth" });
        element.focus();
      }
    }, 500);
  };
  ////////////////////////////////////

  /////////////SCROLL PARA CUANDO APARECE EL BOTON DE SERVICIOS Y PARA CUANDO HACEMOS CLICK EN LASER/////
  const executeScroll = () => {
    setTimeout(() => {
      const element = myRef?.current;
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
        const elementRect = element.getBoundingClientRect();
        const absoluteElementTop = elementRect.top + window.scrollY;
        const middle = absoluteElementTop - window.innerHeight / 10;
        window.scrollTo({ top: middle, behavior: "smooth" });
        element.focus();
      }
    }, 500);
  };
  ///////////////////////////////////////
  const handleClickServices = (value, text, i, id) => {

     props.setDisabledTextAreaAnotherQuery(true);
    props.setDisabledButtonSendEmail(true);
    setdisableButtom(true);
    props.setShowWriting(true);
    setTimeout(() => {
      setdisableButtom(false);
      props.setShowWriting(false);
    }, 1000);

    props.setCheckTerms(false);
    props.setCheckTermsAnotherQuery(false);
    props.setOptionButtonServices(id);
    setChekedServiceTattoo(text);
    // setDot(i);
    if (value === false) {
 
      // nunca es tatuaje
      setMessageLoading2(true);
      if(props.arrayComponentServices === undefined){
        setShowEmail(false);
              // setFormComponent(props, block2, 0);
              backComponent(props, block);
              clearFormData(props, block, { service: false });
      }
      if (props.arrayComponentServices.length !== 0) {
        backComponent(props, block, 1);
        clearFormData(props, block, { service: false }, 1);
        props.setContact(false);
        props.setCity("");
        props.setEmailOrPhone(false);
      } else {

        setShowEmail(false);
        // setFormComponent(props, block2, 0);
        backComponent(props, block);
        clearFormData(props, block, { service: false });
      }

      props.setTypeArray(1);
      if (id !== "anotherQuery") {
        

        setShowEmail(true);
        setTimeout(() => {
          setShowEmail(false);
          setTimeout(() => {
            setShowEmail(true);
          });
        });
        if (props.arrayComponentServices.length !== 0) {

          backComponent(props, block, 1);
          setTimeout(() => {
            nextComponent(props, block, 1);
          }, 10);
          props.setQuery("");
        }
        setOptionButton(id);
        setTimeout(() => {
          setMessageLoading2(false);
          setButtonWhatsapp(true);
          // setOptionButton(id);
        }, 1000);
        setButtonWhatsapp(false);
      } else {

        setMessageLoading2(false);
        setButtonWhatsapp(true);
        setOptionButton(id);
      }
    } else {
 
       setOptionButton(id);
      props.setCity("");
      props.setContact(null);
      setButtonWhatsapp(false);
      backComponent(props, block, 0);
      clearFormData(props, block, { service: false }, 0);
      props.setTypeArray(0);
      nextComponent(props, block, 0);
      setShowEmail(false);
      if (props.arrayComponentServices.length !== 0) {

        props.setEmailOrPhone(true);
        props.setQuery("");
      }
    }
  };
  return (
    <>
      {messageLoading === true && <MessageLoading />}
      {messageLoading === false && (
        <>
          <Form.Group>
            <div
              className="divGenericLabelFormChat marginTopLabelGenericFormChat"
              ref={props.arrayComponentInitial[1].component === block.component ? null : myRef1}
            >
              <Form.Label className="generic01LabelFormChat genericLabelFormChatBubble borderColorLabelVar backgroundColorContainer">
                {props.formData?.map((data, index) => (
                  <React.Fragment key={index}>
                    {data?.name_budget}
                  </React.Fragment>
                ))}
                <span className="textBoldFormChat"> {t("service.text1B")}</span>
              </Form.Label>
            </div>
          </Form.Group>
          <div className="divGenericColumnInputFormChat mb-4">
            <div
              id="idCityFormChat"
              className="divGenericInputTattooFormChat div-zindex"
            >
              {showServiceArrayObjet?.map((data, i) => (
                <Form.Check
                  key={data.id}
                  className="borderNoneFormChatSe"
                  type="radio"
                  id={`check-api-${data.id}`}
                >
                  <div
                    className={
                      disableButtom
                        ? "inputGenericFormChat inputDisabled"
                        : "inputGenericFormChat "
                    }
                    onClick={() =>
                      handleClickServices(data.value, data.text, i, data.id)
                    }
                  >
                    <div className="">
                      <div className="divRadioBisFormChat">
                        <Form.Check.Input
                          className="inputRadioFormChat"
                          type="radio"
                          isValid
                          checked={data.text === chekedServiceTattoo}
                          readOnly
                        />
                        <Form.Check.Label className="divTextDefinitionLabel">
                          <p className="fisrtCapitalize">{data.text}</p>
                        </Form.Check.Label>
                      </div>
                    </div>
                  </div>
                </Form.Check>
              ))}
            </div>
          </div>
        </>
      )}


      {optionButton === "laser" &&
        props.user.check_tattoo === 1 &&
        props?.arrayComponentServices?.length === 0 && (
          <>
            {messageLoading2 === true && (
              <MessageLoading imgLogoInitial={props.initailOrImage} />
            )}
            {messageLoading2 === false && (
              <Form.Group>
                <div
                  className="divGenericLabelFormChat marginTopLabelGenericFormChat"
                  ref={myRef}
                >
                  <Form.Label className="generic01LabelFormChat borderColorLabelVar genericLabelFormChatBubble backgroundColorContainer">
                    {t("service.text2")}
                    <span className="textBoldFormChat">
                      {t("service.text2B")}
                    </span>
                    {t("service.text2C")}
                  </Form.Label>
                </div>
              </Form.Group>
            )}
          </>
        )}
      {optionButton === "anotherQuery" && (
        <>
          {messageLoading2 === false && (
            <AnotherQuery props={props} block={block} />
          )}
        </>
      )}
      {showEmail &&
        props.user.check_tattoo === 0 &&
        props?.arrayComponentServices?.length === 0 && (
          <Email props={props} block={block} />
        )}
      {props?.arrayComponentServices?.length === 0 &&
        buttonWhatsapp === true &&
        optionButton !== "anotherQuery" && (
          <div className="divButtonFormTemplate bounce mt-4" ref={myRef}>
            <ButtonServices
              buttonClass={"botonFormTemplate2"}
              handle={() => servicesTextMessageResponse(optionButton, props, t)}
              props={props}
            />
          </div>
        )}
    </>
  );
};
