import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Form } from "react-bootstrap";
import { MessageLoading } from "../MessageLoading/MessageLoading";
import { OldClientData } from "./OldClientData";
import {
  nextComponent,
  setFormComponent,
  setFormComponentInfo,
} from "../../utils/nextComponent";

export const OldClient = ({ block, props }) => {
  const [t] = useTranslation("global");
  const [messageLoading, setMessageLoading] = useState(null);
  const [old_client, setOldClient] = useState("");
  const myRef2 = useRef(null);
  let previouslyPiercingDataArrayObject = OldClientData();

  useEffect(() => {
    setTimeout(() => {
      setMessageLoading(false);
      props.setShowWriting(false);
      executeScroll();
    }, 2000);
    props.setShowWriting(true);
    setMessageLoading(true);
  }, []);

  const executeScroll = () => {
    setTimeout(() => {
      if (myRef2?.current) {
        myRef2.current.scrollIntoView({ behavior: "smooth" });
      }
    }, 100);
  };
  const handleChange = (value, text) => {
    setOldClient(value);
    setFormComponent(props, block, { old_client: value });
    setFormComponentInfo(props, block, { old_client: text });
    nextComponent(props, block);
  };

  return (
    <>
      {messageLoading === true ? (
        <MessageLoading />
      ) : (
        <>
          <Form.Group>
            <div className="divGenericLabelFormChat" ref={myRef2}>
              <Form.Label className="generic01LabelFormChat genericLabelFormChatBubble borderColorLabelVar backgroundColorContainer">
                {t("piercingpreviously.text")}
              </Form.Label>
            </div>
          </Form.Group>
          <div className="divGenericColumnInputFormChat">
            <div id="idTimeFormChat" className="divGenericInputTattooFormChat">
              {previouslyPiercingDataArrayObject?.map((data) => (
                <Form.Check
                  key={data.id}
                  className="borderNoneFormChatSe"
                  type="radio"
                  id={`check-api-${data.id}`}
                >
                  <div className="inputGenericFormChat">
                    <div className="divRadioBisFormChat">
                      <Form.Check.Input
                        className="inputRadioFormChat"
                        type="radio"
                        isValid
                        checked={old_client === data.value}
                        onChange={() => handleChange(data.value, data.text)}
                      />
                      <Form.Check.Label className="divTextDefinitionLabel">
                        <p>{data.text}</p>
                      </Form.Check.Label>
                    </div>
                  </div>
                </Form.Check>
              ))}
            </div>
          </div>
        </>
      )}
    </>
  );
};
