export const responseToClientMessage = (formData, user, t) => {
  const {
    name_budget,
    description,
    size,
    body_part,
    budget_color,
    email_budget,
    phone_budget,
    check_city,
    time_availability,
    check_skintone,
    check_employeds,
    images,
    type_jewelry,
    old_client,
  } = formData.reduce((result, obj) => {
    const [key, value] = Object.entries(obj)[0];
    result[key] = value;
    return result;
  }, {});

  let text = "";
  let modifiedUrls = "";

  if (images && images.length !== 0) {
    const modifiedUrls2 =
      images &&
      images
        .map((url, index) => {
          const modifiedUrl = url
            .replace("?alt", "%3Falt")
            .replace("=media", "%3Dmedia");
          return `${modifiedUrl}%0d%0a`;
        })
        .join("%0d%0a");
    modifiedUrls = `%0d%0a%0d%0a${t(
      "SendWhatsAppForm.images"
    )}%0d%0a${modifiedUrls2}`;
  }

  const keyMapping = {
    size: `${t("SendWhatsAppForm.size")} ${size}`,
    body_part: `${t("SendWhatsAppForm.bodyPart")} ${body_part}`,
    budget_color: `${t("SendWhatsAppForm.colorIn1")} ${budget_color}`,
    email_budget: `${t("SendWhatsAppForm.email")} ${email_budget}`,
    phone_budget: `${t("SendWhatsAppForm.phone")} ${phone_budget}`,
    check_city: `${t("SendWhatsAppForm.city")} ${check_city}`,
    time_availability: `${t("SendWhatsAppForm.time")} ${time_availability}`,
    check_skintone: `${t("SendWhatsAppForm.skinTone")} ${check_skintone}`,
    check_employeds: `${t("SendWhatsAppForm.artist")} ${check_employeds}`,
    type_jewelry: `${t("SendWhatsAppForm.typeJewelry")}} ${type_jewelry}`,
    old_client: `${t("SendWhatsAppForm.oldClient")} ${old_client}`,
  };

  // text += `¡${t("SendWhatsAppForm.hello")}${name_budget}! ${t(
  //   "SendWhatsAppForm.Iam"
  // )} ${user.studio_name}, ${t("SendWhatsAppForm.seeInformacion")}%0d%0a%0d%0a${t(
  //   "SendWhatsAppForm.summaryRequest"
  // )}%0d%0a%0d%0a${
  //   description === "" || description === undefined
  //     ? ""
  //     : `${t("SendWhatsAppForm.description")} ${description}%0d%0a`
  // }`;

  if (name_budget) {
    text += `¡${t("SendWhatsAppForm.hello")}${name_budget}! `;
  }

  if (user && user.studio_name) {
    text += `${t("SendWhatsAppForm.Iam")} ${user.studio_name}, ${t(
      "SendWhatsAppForm.seeInformacion"
    )}%0d%0a%0d%0a${t("SendWhatsAppForm.summaryRequest")}%0d%0a%0d%0a`;
  }

  if (description) {
    text += `${
      description === "" || description === undefined
        ? ""
        : `${t("SendWhatsAppForm.description")} ${description}%0d%0a`
    }`;
  }

  text += formData
    .filter((item) =>
      Object.keys(item).every((key) => keyMapping[key] !== undefined)
    )
    .map((item) => {
      return Object.keys(item)
        .map((key) => `${keyMapping[key]}`)
        .join("%0d%0a");
    })
    .join("%0d%0a");
  return `${text}${modifiedUrls}`;
};
