import { useTranslation } from "react-i18next";
import marfil from "../../../../assets/images/imagenMarfil.png";
import beige from "../../../../assets/images/imagenBeige.png";
import lightBrown from "../../../../assets/images/imagenLightBrown.png";
import mediumBrown from "../../../../assets/images/imagenMediumBrown.png";
import darkBrown from "../../../../assets/images/imagenDarkBrown.png";
import veryDarkBrown from "../../../../assets/images/imagenVeryDarkBrown.png";

export const SkinToneData = () => {
  const [t] = useTranslation("global");

  return [
    {
      id: "marfil",
      text: `${t("SkinToneData.ivory")}`,
      value: "Marfil",
      image: marfil,
      imageMessage:
        "https://firebasestorage.googleapis.com/v0/b/connectattoo.appspot.com/o/11692867586406?alt=media",
      type: "radio",
    },
    {
      id: "beige",
      text: `${t("SkinToneData.beige")}`,
      value: "Beige",
      image: beige,
      imageMessage:
        "https://firebasestorage.googleapis.com/v0/b/connectattoo.appspot.com/o/11692867585933?alt=media",
      type: "radio",
    },
    {
      id: "moreno-claro",
      text: `${t("SkinToneData.lightBrown")}`,
      value: "Moreno claro",
      image: lightBrown,
      imageMessage:
        "https://firebasestorage.googleapis.com/v0/b/connectattoo.appspot.com/o/11692867586772?alt=media",
      type: "radio",
    },
    {
      id: "moreno-intermedio",
      text: `${t("SkinToneData.mediumBrown")}`,
      value: "Moreno intermedio",
      image: mediumBrown,
      imageMessage:
        "https://firebasestorage.googleapis.com/v0/b/connectattoo.appspot.com/o/11692867587194?alt=media",
      type: "radio",
    },
    {
      id: "moreno-oscuro",
      text: `${t("SkinToneData.darkBrown")}`,
      value: "Moreno oscuro",
      image: darkBrown,
      imageMessage:
        "https://firebasestorage.googleapis.com/v0/b/connectattoo.appspot.com/o/11692867065535?alt=media",
      type: "radio",
    },
    {
      id: "moreno-muy-oscuro",
      text: `${t("SkinToneData.veryDarkBrown")}`,
      value: "Moreno muy oscuro",
      image: veryDarkBrown,
      imageMessage:
        "https://firebasestorage.googleapis.com/v0/b/connectattoo.appspot.com/o/11692867245384?alt=media",
      type: "radio",
    },
  ];
};
