import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Form } from "react-bootstrap";
import { MessageLoading } from "../MessageLoading/MessageLoading";
import { TimeData } from "./TimeData";
import {
  nextComponent,
  setFormComponent,
  setFormComponentInfo,
} from "../../utils/nextComponent";

export const Time = ({ block, props }) => {
  const [t] = useTranslation("global");
  const [messageLoading, setMessageLoading] = useState(null);
  const [time, setTime] = useState(false);
  const myRef2 = useRef(null);
  let timeTattooDataArrayObject = TimeData();

  useEffect(() => {
    props.setShowWriting(true);
    setMessageLoading(true);

    setTimeout(() => {
      setMessageLoading(false);
      props.setShowWriting(false);
    }, 1000);

    setTimeout(() => {
      setMessageLoading(null);
      executeScroll();
    }, 1000);
  }, []);

  const executeScroll = () => {
    setTimeout(() => {
      if (myRef2?.current) {
        myRef2.current.scrollIntoView({ behavior: "smooth" });
      }
    }, 100);
  };

  const handleChange = (value, text) => {
    setTime(value);
    setFormComponent(props, block, { time_availability: value });
    setFormComponentInfo(props, block, { time_availability: text });
    nextComponent(props, block);
  };

  useEffect(() => {
    let timeInfoFinally;
    let timeInfo = timeTattooDataArrayObject?.filter(
      (data) => data?.value === time && data.text
    );

    timeInfoFinally = timeInfo[0]?.text;
    setFormComponentInfo(props, block, { time_availability: timeInfoFinally });
  }, [props.language]);

  return (
    <>
      {messageLoading === true && <MessageLoading />}

      {messageLoading === null && (
        <>
          <Form.Group>
            <div
              className="divGenericLabelFormChat marginTopLabelGenericFormChat"
              ref={myRef2}
            >
              <Form.Label className="mt-4 generic01LabelFormChat genericLabelFormChatBubble borderColorLabelVar backgroundColorContainer">
                {t("time.text2")}
                <span className="textBoldFormChat">{t("time.text3")}</span>
              </Form.Label>
            </div>
          </Form.Group>
          <div className="divGenericColumnInputFormChat">
            <div
              id="idTimeFormChat"
              className="divGenericInputTattooFormChat div-zindex"
            >
              {timeTattooDataArrayObject?.map((data) => (
                <Form.Check
                  key={data.id}
                  className="borderNoneFormChatSe"
                  type="radio"
                  id={`check-api-${data.id}`}
                >
                  <div
                    className="inputGenericFormChat"
                    onClick={() => handleChange(data.value, data.text)}
                  >
                    <div className="divRadioBisFormChat">
                      <Form.Check.Input
                        className="inputRadioFormChat"
                        type="radio"
                        isValid
                        readOnly
                        checked={time === data.value}
                        // onChange={() => handleChange(data.value, data.text)}
                      />
                      <Form.Check.Label className="divTextDefinitionLabel">
                        <p>{data.text}</p>
                      </Form.Check.Label>
                    </div>
                  </div>
                </Form.Check>
              ))}
            </div>
          </div>
        </>
      )}
    </>
  );
};
