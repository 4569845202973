import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Form } from "react-bootstrap";
import { MessageLoading } from "../MessageLoading/MessageLoading";
import { nextComponent } from "../../utils/nextComponent";

export const FinishText = ({ block, props }) => {
  const [t] = useTranslation("global");
  const [messageLoading, setMessageLoading] = useState(null);

  useEffect(() => {
    if (block.conditional) {
      props.setShowWriting(true);
      setMessageLoading(true);
      setTimeout(() => {
        setMessageLoading(false);
        props.setShowWriting(false);
        nextComponent(props, block);
      }, 1000);
    }
  }, []);

  return (
    <>
      {messageLoading === true && <MessageLoading scrollRef={false} />}
      {messageLoading === false && (
        <>
          <div className="marginBottom8px mt-4" style={{ marginLeft: "16px" }}>
            <Form.Label className="genericLabelFormChat borderColorLabelVar backgroundColorContainer">
              {t("time.text1")}
            </Form.Label>
          </div>
        </>
      )}
    </>
  );
};
