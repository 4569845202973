import React from "react";
import "./icon-danger.css";

export const IconDanger = () => {
  return (
    <div className="divIconDanger">
      <div className="iconDanger">
        <div className="danger"></div>
      </div>
    </div>
  );
};
