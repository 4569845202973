import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import "../../assets/css/index.css";

export const InitialsButtons = ({ changeState, phone }) => {
  const [t] = useTranslation("global");
  const [containerHeight, setContainerHeight] = useState(0);

  useEffect(() => {
    let containers = document.getElementsByClassName("chat-header");
    if (containers.length > 0) {
      let container = containers[0];
      let heightContainer = container.offsetHeight;
      setContainerHeight(heightContainer);
    }
  }, []);

  const WhatsappMessage = () => {
    let whatsapp = `https://api.whatsapp.com/send?phone=+${phone}&text=${t("initialButtons.text8")}`;
    window.open(`${whatsapp}`, "_blank");
  };

  return (
    <Container
      fluid
      translate="no"
      className="containerTemplate contentContainer urbanist bgButtonsContainer"
    >
      <div className="colorTextVar">
        <div className="titleInitalButtonsContainer">
          <h4>{t("initialButtons.title")}</h4>
        </div>
        <div
          className="initalButtonsContainer "
          style={{
            marginTop: `${containerHeight}px`,
          }}>
          <Button onClick={() => changeState(t("initialButtons.text2"))} >
            {t("initialButtons.text1")} <b>{t("initialButtons.text1-2")}</b>
          </Button>
          <Button
            onClick={() => changeState(t("initialButtons.text4"))}>
            {t("initialButtons.text3")} <b>{t("initialButtons.text3-2")}</b> 
            {t("initialButtons.text3-3")}
          </Button>
          <Button onClick={() => changeState(t("initialButtons.text6"))}>
          {t("initialButtons.text5")} <b> {t("initialButtons.text5-2")}</b> 
          {t("initialButtons.text5-3")}
          </Button>
          {/* este va directo al whatsapp */}
          <Button onClick={WhatsappMessage}>
          {t("initialButtons.text7")}<b> {t("initialButtons.text7-2")}</b>
          {t("initialButtons.text7-3")}
          </Button>

        </div>
      </div>
    </Container>
  );
};
