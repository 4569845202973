import { useTranslation } from "react-i18next";
import imagesEar from "../../../../assets/imagesPiercing/Piercing-ear.png";
import imagesEarCorrection from "../../../../assets/imagesPiercing/Piercing-ear-correction.png";
import imagesEarDesign from "../../../../assets/imagesPiercing/Piercing-ear-design.png";
import imagesEarLobe from "../../../../assets/imagesPiercing/Piercing-ear-lobe.png";
import imagesEarDillatation from "../../../../assets/imagesPiercing/Piercing-ear-dillatation.png";
import imagesEarHelix from "../../../../assets/imagesPiercing/Piercing-ear-helix.png";
import imagesEarHelixForward from "../../../../assets/imagesPiercing/Piercing-ear-helixforward.png";
import imagesEarScapha from "../../../../assets/imagesPiercing/Piercing-ear-scapha.png";
import imagesEarTragus from "../../../../assets/imagesPiercing/Piercing-ear-tragus.png";
import imagesEarAntiTragus from "../../../../assets/imagesPiercing/Piercing-ear-antitragus.png";
import imagesEarDaith from "../../../../assets/imagesPiercing/Piercing-ear-daith.png";
import imagesEarRook from "../../../../assets/imagesPiercing/Piercing-ear-rook.png";
import imagesEarIndustrial from "../../../../assets/imagesPiercing/Piercing-ear-industrial.png";
import imagesEarSurfaceTragus from "../../../../assets/imagesPiercing/Piercing-ear-surfacetragus.png";
import imagesTongueBodyPart from "../../../../assets/imagesPiercing/Piercing-tongue-bodyPart.png";
import imagesTongue from "../../../../assets/imagesPiercing/Piercing-tongue.png";
import imagesTongueVertical from "../../../../assets/imagesPiercing/Piercing-tongue-verticalsymmetry.png";
import imagesDolphinBite from "../../../../assets/imagesPiercing/Piercing-mouth-dolphinbites.png";
import imagesNose from "../../../../assets/imagesPiercing/Piercing-nose.png";
import imagesNoseNostril from "../../../../assets/imagesPiercing/Piercing-nose-nostril.png";
import imagesNoseSymmetryNostril from "../../../../assets/imagesPiercing/Piercing-nose-nostrilsymmetry.png";
import imagesNoseSymmetrySeptum from "../../../../assets/imagesPiercing/Piercing-nose-septum.png";
import imagesNosehighnostril from "../../../../assets/imagesPiercing/Piercing-nose-highnostril.png";
import imagesNosehighnostrilsymmetry from "../../../../assets/imagesPiercing/Piercing-nose-highnostrilsymmetry.png";
import imagesMouth from "../../../../assets/imagesPiercing/Piercing-mouth.png";
import imagesMouthLabret from "../../../../assets/imagesPiercing/Piercing-mouth-labret.png";
import imagesVerticalLabretTop from "../../../../assets/imagesPiercing/Piercing-mouth-verticallabrettop.png";
import imagesMouthMedusa from "../../../../assets/imagesPiercing/Piercing-mouth-medusa.png";
import imagesMouthVerticalLabretBottom from "../../../../assets/imagesPiercing/Piercing-mouth-bottom-labret.png";
import imagesMouthLateralLLabret from "../../../../assets/imagesPiercing/Piercing-mouth-laterallabret.png";
import imagesMouthSnakebite from "../../../../assets/imagesPiercing/Piercing-mouth-snakebite.png";
import imagesMouthMonroe from "../../../../assets/imagesPiercing/Piercing-mouth-monroe.png";
import imagesMouthAshley from "../../../../assets/imagesPiercing/Piercing-mouth-ashley.png";
import imagesFacial from "../../../../assets/imagesPiercing/Piercing-facial.png";
import imagesAnotherFacialEyebrow from "../../../../assets/imagesPiercing/Piercing-eyebrow.png";
import imagesAnotherFacialsymmetry from "../../../../assets/imagesPiercing/Piercing-eyebrow-symmetry.png";
import imagesAnotherFacialBridge from "../../../../assets/imagesPiercing/Piercing-eyebrow-bridge.png";
import imagesBelly from "../../../../assets/imagesPiercing/Piercing-belly.png";
import imagesBellyTop from "../../../../assets/imagesPiercing/Piercing-belly-top.png";
import imagesBellyBottom from "../../../../assets/imagesPiercing/Piercing-belly-bottom.png";
import imagesBellySymmetry from "../../../../assets/imagesPiercing/Piercing-belly-verticalsymmetry.png";
import imagesMicrodermal from "../../../../assets/imagesPiercing/Piercing-microdermal.png";
import imagesMicrodermalCheekbone from "../../../../assets/imagesPiercing/Piercing-microdermal-cheekbone.png";
import imagesMicrodermalForehead from "../../../../assets/imagesPiercing/Piercing-microdermal-forehead.png";
import imagesMicrodermalChest from "../../../../assets/imagesPiercing/Piercing-microdermal-chest-1.png";
import imagesMicrodermalNape from "../../../../assets/imagesPiercing/Piercing-microdermal-nape.png";
import imagesMicrodermalBelly from "../../../../assets/imagesPiercing/Piercing-microdermal-belly.png";
import imagesMicrodermalFemMascIntimate from "../../../../assets/imagesPiercing/Piercing-microdermal-femmascintimate.png";
import imagesMicrodermalOther from "../../../../assets/imagesPiercing/Piercing-microdermal-other.png";
import imagesFemintimate from "../../../../assets/imagesPiercing/Piercing-femintimate.png";
import imagesFemIntimateVHC from "../../../../assets/imagesPiercing/Piercing-femintimate-VHC.png";
import imagesFemIntimateHCH from "../../../../assets/imagesPiercing/Piercing-femintimate-HCH.png";
import imagesFemIntimateCristina from "../../../../assets/imagesPiercing/Piercing-femintimate-Cristina.png";
import imagesFemIntimateLabiaMenora from "../../../../assets/imagesPiercing/Piercing-femintimate-Labiamenora.png";
import imagesFemIntimateLabiaMajora from "../../../../assets/imagesPiercing/Piercing-femintimate-Labiamajora.png";
import imagesMascIntimate from "../../../../assets/imagesPiercing/Piercing-mascintimate.png";
import imagesMascIntimatePrinceAlbert from "../../../../assets/imagesPiercing/Piercing-mascintimate-princealbert.png";
import imagesMascIntimatePrinceAlbertInvert from "../../../../assets/imagesPiercing/Piercing-mascintimate-princealbertinvert.png";
import imagesMascIntimateGuiche from "../../../../assets/imagesPiercing/Piercing-mascintimate-guiche.png";
import imagesMascIntimateHafada from "../../../../assets/imagesPiercing/Piercing-mascintimate-hafada.png";
import imagesMascIntimateFrenum from "../../../../assets/imagesPiercing/Piercing-mascintimate-frenum.png";
import imagesMascIntimateLorum from "../../../../assets/imagesPiercing/Piercing-mascintimate-lorum.png";
import imagesNippleBodyPart from "../../../../assets/imagesPiercing/Piercing-nipples-bodyPart.png";
import imagesNipple from "../../../../assets/imagesPiercing/Piercing-nipple.png";
import imagesNippleSymmetry from "../../../../assets/imagesPiercing/Piercing-nipple-symmetry.png";

export const BodyPiercingData = () => {
  const [t] = useTranslation("global");

  return [
    {
      id: "oreja",
      text: `${t("bodyPiercingData.bodyPartPiercingOreja")}`,
      value: "Oreja",
      image: imagesEar,
      type: "radio",
      parts: [
        {
          id: "lobulo",
          text: `${t("bodyPiercingData.bodyPartPiercingLobulo")}`,
          value: "Lóbulo",
          image: imagesEarLobe,
          type: "radio",
          classNameBorder: "borderBottom",
        },
        {
          id: "helix",
          text: `${t("bodyPiercingData.bodyPartPiercingHelix")}`,
          value: "Helix",
          image: imagesEarHelix,
          type: "radio",
          classNameBorder: "borderBottom",
        },
        {
          id: "helix forward",
          text: `${t("bodyPiercingData.bodyPartPiercingHelixFowrard")}`,
          value: "Helix forward",
          image: imagesEarHelixForward,
          type: "radio",
          classNameBorder: "borderBottom",
        },
        {
          id: "scapha",
          text: `${t("bodyPiercingData.bodyPartPiercingScapha")}`,
          value: "Scapha",
          image: imagesEarScapha,
          type: "radio",
          classNameBorder: "borderBottom",
        },
        {
          id: "tragus",
          text: `${t("bodyPiercingData.bodyPartPiercingTragus")}`,
          value: "Tragus",
          image: imagesEarTragus,
          type: "radio",
          classNameBorder: "borderBottom",
        },
        {
          id: "antitragus",
          text: `${t("bodyPiercingData.bodyPartPiercingAntitragus")}`,
          value: "Antitragus",
          image: imagesEarAntiTragus,
          type: "radio",
          classNameBorder: "borderBottom",
        },
        {
          id: "daith",
          text: `${t("bodyPiercingData.bodyPartPiercingDaith")}`,
          value: "Daith",
          image: imagesEarDaith,
          type: "radio",
          classNameBorder: "borderBottom",
        },
        {
          id: "rook",
          text: `${t("bodyPiercingData.bodyPartPiercingRoock")}`,
          value: "Rook",
          image: imagesEarRook,
          type: "radio",
          classNameBorder: "borderBottom",
        },
        {
          id: "industrial",
          text: `${t("bodyPiercingData.bodyPartPiercingIndustrial")}`,
          value: "Industrial",
          image: imagesEarIndustrial,
          type: "radio",
          classNameBorder: "borderBottom",
        },
        {
          id: "surface-tragus",
          text: `${t("bodyPiercingData.bodyPartPiercingSurfaceTragus")}`,
          value: "Surface tragus",
          image: imagesEarSurfaceTragus,
          type: "radio",
          classNameBorder: "borderBottom",
        },
        {
          id: "dilatación",
          text: `${t("bodyPiercingData.bodyPartPiercingDilatación")}`,
          value: "Dilatación",
          image: imagesEarDillatation,
          type: "radio",
          classNameBorder: "borderBottom",
        },
        {
          id: "corregir-asimetria-de-lobulo",
          text: `${t("bodyPiercingData.bodyPartPiercingLobeAsymmetries")}`,
          value: "Corregir asimetrías de lóbulo",
          image: imagesEarCorrection,
          type: "radio",
          classNameBorder: "borderBottom",
        },
        {
          id: "diseño-de-oreja",
          text: `${t("bodyPiercingData.bodyPartPiercingDesignEar")}`,
          value: "Diseño de oreja",
          image: imagesEarDesign,
          type: "radio",
          classNameBorder: "borderBottom",
        },
      ],
    },
    {
      id: "boca",
      text: `${t("bodyPiercingData.bodyPartPiercingBucal")}`,
      value: "Boca",
      image: imagesTongueBodyPart,
      type: "radio",
      parts: [
        {
          id: "lengua",
          text: `${t("bodyPiercingData.bodyPartPiercingLengua")}`,
          value: "Lengua",
          image: imagesTongue,
          type: "radio",
          classNameBorder: "borderBottom",
        },
        {
          id: "simetría-lengua-vertical",
          text: `${t(
            "bodyPiercingData.bodyPartPiercingSimetríalenguavertical"
          )}`,
          value: "Simetría lengua vertical",
          image: imagesTongueVertical,
          type: "radio",
          classNameBorder: "borderBottom",
        },
      ],
    },
    {
      id: "nariz",
      text: `${t("bodyPiercingData.bodyPartPiercingNariz")}`,
      value: "Nariz",
      image: imagesNose,
      type: "radio",
      parts: [
        {
          id: "nostril",
          text: `${t("bodyPiercingData.bodyPartPiercingHelixNostril")}`,
          value: "Nostril",
          image: imagesNoseNostril,
          type: "radio",
          classNameBorder: "borderBottom",
        },
        {
          id: "simetría-nostril",
          text: `${t("bodyPiercingData.bodyPartPiercingSimetríanostril")}`,
          value: "Simetría nostril",
          image: imagesNoseSymmetryNostril,
          type: "radio",
          classNameBorder: "borderBottom",
        },
        {
          id: "septum",
          text: `${t("bodyPiercingData.bodyPartPiercingSeptum")}`,
          value: "Septum",
          image: imagesNoseSymmetrySeptum,
          type: "radio",
          classNameBorder: "borderBottom",
        },
        {
          id: "high-nostril",
          text: `${t("bodyPiercingData.bodyPartPiercingHighnostril")}`,
          value: "High nostril",
          image: imagesNosehighnostril,
          type: "radio",
          classNameBorder: "borderBottom",
        },
        {
          id: "Simetría-high-nostril",
          text: `${t("bodyPiercingData.bodyPartPiercingSimetríahighnostril")}`,
          value: "Simetría high nostril",
          image: imagesNosehighnostrilsymmetry,
          type: "radio",
          classNameBorder: "borderBottom",
        },
        {
          id: "bridge",
          text: `${t("bodyPiercingData.bodyPartPiercingBridge")}`,
          value: "Bridge",
          image: imagesAnotherFacialBridge,
          type: "radio",
          classNameBorder: "borderBottom",
        },
      ],
    },
    {
      id: "labio",
      text: `${t("bodyPiercingData.bodyPartPiercingLabio")}`,
      value: "Labio",
      image: imagesMouth,
      type: "radio",
      parts: [
        {
          id: "labret",
          text: `${t("bodyPiercingData.bodyPartPiercingLabret")}`,
          value: "Labret",
          image: imagesMouthLabret,
          type: "radio",
          classNameBorder: "borderBottom",
        },
        {
          id: "medusa",
          text: `${t("bodyPiercingData.bodyPartPiercingMedusa")}`,
          value: "Medusa",
          image: imagesMouthMedusa,
          type: "radio",
          classNameBorder: "borderBottom",
        },
        {
          id: "vertical-labret-inferior",
          text: `${t(
            "bodyPiercingData.bodyPartPiercingVerticallabretinferior"
          )}`,
          value: "Vertical labret inferior",
          image: imagesMouthVerticalLabretBottom,
          type: "radio",
          classNameBorder: "borderBottom",
        },
        {
          id: "vertical labret superior",
          text: `${t(
            "bodyPiercingData.bodyPartPiercingVerticallabretsuperior"
          )}`,
          value: "Vertical labret superior",
          image: imagesVerticalLabretTop,
          type: "radio",
          classNameBorder: "borderBottom",
        },
        {
          id: "snakebite",
          text: `${t("bodyPiercingData.bodyPartPiercingSnakebite")}`,
          value: "Snakebite",
          image: imagesMouthSnakebite,
          type: "radio",
          classNameBorder: "borderBottom",
        },
        {
          id: "monroe",
          text: `${t("bodyPiercingData.bodyPartPiercingMonroe")}`,
          value: "Monroe",
          image: imagesMouthMonroe,
          type: "radio",
          classNameBorder: "borderBottom",
        },
        {
          id: "ashley",
          text: `${t("bodyPiercingData.bodyPartPiercingAshley")}`,
          value: "Ashley",
          image: imagesMouthAshley,
          type: "radio",
          classNameBorder: "borderBottom",
        },
        {
          id: "dolphin-bites",
          text: `${t("bodyPiercingData.bodyPartPiercingDolphinBites")}`,
          value: "Dolphin Bites",
          image: imagesDolphinBite,
          type: "radio",
          classNameBorder: "borderBottom",
        },
        {
          id: "labret-lateral",
          text: `${t("bodyPiercingData.bodyPartPiercingLabretLateral")}`,
          value: "Labret Lateral",
          image: imagesMouthLateralLLabret,
          type: "radio",
          classNameBorder: "borderBottom",
        },
      ],
    },
    {
      id: "otros-faciales",
      text: `${t("bodyPiercingData.bodyPartPiercingOtrosfaciales")}`,
      value: "Otros faciales",
      image: imagesFacial,
      type: "radio",
      parts: [
        {
          id: "ceja",
          text: `${t("bodyPiercingData.bodyPartPiercingCeja")}`,
          value: "Ceja",
          image: imagesAnotherFacialEyebrow,
          type: "radio",
          classNameBorder: "borderBottom",
        },
        {
          id: "simetría-de-cejas",
          text: `${t("bodyPiercingData.bodyPartPiercingSimetríadecejas")}`,
          value: "Simetría de cejas",
          image: imagesAnotherFacialsymmetry,
          type: "radio",
          classNameBorder: "borderBottom",
        },
      ],
    },
    {
      id: "ombligo",
      text: `${t("bodyPiercingData.bodyPartPiercingOmbligo2")}`,
      value: "Ombligo",
      image: imagesBelly,
      type: "radio",
      parts: [
        {
          id: "superior",
          text: `${t("bodyPiercingData.bodyPartPiercingSuperior")}`,
          value: "Superior",
          image: imagesBellyTop,
          type: "radio",
          classNameBorder: "borderBottom",
        },
        {
          id: "inferior",
          text: `${t("bodyPiercingData.bodyPartPiercingInferior")}`,
          value: "Inferior",
          image: imagesBellyBottom,
          type: "radio",
        },
        {
          id: "simetría-vertical",
          text: `${t("bodyPiercingData.bodyPartPiercingSimetríavertical")}`,
          value: "Simetría vertical",
          image: imagesBellySymmetry,
          type: "radio",
          classNameBorder: "borderBottom",
        },
      ],
    },
    {
      id: "pezón",
      text: `${t("bodyPiercingData.bodyPartPiercingPezón")}`,
      value: "Pezón",
      image: imagesNippleBodyPart,
      type: "radio",
      parts: [
        {
          id: "Pezón-masculino",
          text: `${t("bodyPiercingData.bodyPartPiercingPezónmasculino")}`,
          value: "Pezón masculino",
          image: imagesNipple,
          type: "radio",
          classNameBorder: "borderBottom",
        },
        {
          id: "Pezón-femenino",
          text: `${t("bodyPiercingData.bodyPartPiercingPezónfemenino")}`,
          value: "Pezón femenino",
          image: imagesNipple,
          type: "radio",
        },
        {
          id: "Simetría-de-pezón-masculino",
          text: `${t(
            "bodyPiercingData.bodyPartPiercingSimetríadepezónmasculino"
          )}`,
          value: "Simetría de pezón masculino",
          image: imagesNippleSymmetry,
          type: "radio",
          classNameBorder: "borderBottom",
        },
        {
          id: "Simetría-de-pezón-femenino",
          text: `${t(
            "bodyPiercingData.bodyPartPiercingSimetríadepezónfemenino"
          )}`,
          value: "Simetría de pezón femenino",
          image: imagesNippleSymmetry,
          type: "radio",
          classNameBorder: "borderBottom",
        },
      ],
    },
    {
      id: "pezón invertido",
      text: `${t("bodyPiercingData.bodyPartPiercingPezóninvertido")}`,
      value: "Pezón invertido",
      image: imagesNippleBodyPart,
      type: "radio",
      parts: [
        {
          id: "Pezón-invertido",
          text: `${t("bodyPiercingData.bodyPartPiercingPezóninvertido")}`,
          value: "Pezón invertido",
          image: imagesNipple,
          type: "radio",
          classNameBorder: "borderBottom",
        },
        {
          id: "Simetría-de-pezón-invertido",
          text: `${t(
            "bodyPiercingData.bodyPartPiercingSimetriaPezonInvertido"
          )}`,
          value: "Simetría de pezón invertido",
          image: imagesNippleSymmetry,
          type: "radio",
          classNameBorder: "borderBottom",
        },
      ],
    },
    {
      id: "intimo femenino",
      text: `${t("bodyPiercingData.bodyPartPiercingÍntimofemenino2")}`,
      value: "Íntimo femenino",
      image: imagesFemintimate,
      type: "radio",
      parts: [
        {
          id: "vhc",
          text: `${t("bodyPiercingData.bodyPartPiercingVHC")}`,
          value: "VHC",
          image: imagesFemIntimateVHC,
          type: "radio",
          classNameBorder: "borderBottom",
        },
        {
          id: "hch",
          text: `${t("bodyPiercingData.bodyPartPiercingHCH")}`,
          value: "HCH",
          image: imagesFemIntimateHCH,
          type: "radio",
          classNameBorder: "borderBottom",
        },
        {
          id: "cristina",
          text: `${t("bodyPiercingData.bodyPartPiercingCristina")}`,
          value: "Cristina",
          image: imagesFemIntimateCristina,
          type: "radio",
          classNameBorder: "borderBottom",
        },
        {
          id: "labios-menores",
          text: `${t("bodyPiercingData.bodyPartPiercingLabiosmenores")}`,
          value: "Labios menores",
          image: imagesFemIntimateLabiaMenora,
          type: "radio",
          classNameBorder: "borderBottom",
        },
        {
          id: "labios-mayores",
          text: `${t("bodyPiercingData.bodyPartPiercingLabiosmayores")}`,
          value: "Labios mayores",
          image: imagesFemIntimateLabiaMajora,
          type: "radio",
          classNameBorder: "borderBottom",
        },
      ],
    },
    {
      id: "íntimo masculino",
      text: `${t("bodyPiercingData.bodyPartPiercingÍntimomasculino2")}`,
      value: "Íntimo masculino",
      image: imagesMascIntimate,
      type: "radio",
      parts: [
        {
          id: "príncipe-Alberto",
          text: `${t("bodyPiercingData.bodyPartPiercingPríncipeAlberto")}`,
          value: "Príncipe Alberto",
          image: imagesMascIntimatePrinceAlbert,
          type: "radio",
          classNameBorder: "borderBottom",
        },
        {
          id: "Príncipe-Alberto-invertido",
          text: `${t(
            "bodyPiercingData.bodyPartPiercingPríncipeAlbertoInvertido"
          )}`,
          value: "Príncipe Alberto invertido",
          image: imagesMascIntimatePrinceAlbertInvert,
          type: "radio",
          classNameBorder: "borderBottom",
        },
        {
          id: "guiche",
          text: `${t("bodyPiercingData.bodyPartPiercingGuiche")}`,
          value: "Guiche",
          image: imagesMascIntimateGuiche,
          type: "radio",
          classNameBorder: "borderBottom",
        },
        {
          id: "hafada",
          text: `${t("bodyPiercingData.bodyPartPiercingHafada")}`,
          value: "Hafada",
          image: imagesMascIntimateHafada,
          type: "radio",
          classNameBorder: "borderBottom",
        },
        {
          id: "frenum",
          text: `${t("bodyPiercingData.bodyPartPiercingFrenum")}`,
          value: "Frenum",
          image: imagesMascIntimateFrenum,
          type: "radio",
          classNameBorder: "borderBottom",
        },
        {
          id: "lorum",
          text: `${t("bodyPiercingData.bodyPartPiercingLorum")}`,
          value: "Lorum",
          image: imagesMascIntimateLorum,
          type: "radio",
          classNameBorder: "borderBottom",
        },
      ],
    },
    {
      id: "microdermal",
      text: `${t("bodyPiercingData.bodyPartPiercingMicrodermal")}`,
      value: "Microdermal",
      image: imagesMicrodermal,
      type: "radio",
      parts: [
        {
          id: "pómulo",
          text: `${t("bodyPiercingData.bodyPartPiercingPómulo")}`,
          value: "Pómulo",
          image: imagesMicrodermalCheekbone,
          type: "radio",
          classNameBorder: "borderBottom",
        },
        {
          id: "entrecejo",
          text: `${t("bodyPiercingData.bodyPartPiercingEntrecejo")}`,
          value: "Entrecejo",
          image: imagesMicrodermalForehead,
          type: "radio",
          classNameBorder: "borderBottom",
        },
        {
          id: "pecho",
          text: `${t("bodyPiercingData.bodyPartPiercingPecho")}`,
          value: "Pecho",
          image: imagesMicrodermalChest,
          type: "radio",
          classNameBorder: "borderBottom",
        },
        {
          id: "nuca",
          text: `${t("bodyPiercingData.bodyPartPiercingNuca")}`,
          value: "Nuca",
          image: imagesMicrodermalNape,
          type: "radio",
          classNameBorder: "borderBottom",
        },
        {
          id: "Ombligo ",
          text: `${t("bodyPiercingData.bodyPartPiercingOmbligo3")}`,
          value: "Ombligo ",
          image: imagesMicrodermalBelly,
          type: "radio",
          classNameBorder: "borderBottom",
        },
        {
          id: "pubis-femenino",
          text: `${t("bodyPiercingData.bodyPartPiercingÍntimofemenino")}`,
          value: "Pubis femenino",
          image: imagesMicrodermalFemMascIntimate,
          type: "radio",
          classNameBorder: "borderBottom",
        },
        {
          id: "pubis-masculino",
          text: `${t("bodyPiercingData.bodyPartPiercingÍntimomasculino")}`,
          value: "Pubis masculino",
          image: imagesMicrodermalFemMascIntimate,
          type: "radio",
          classNameBorder: "borderBottom",
        },
        {
          id: "otros",
          text: `${t("bodyPiercingData.bodyPartPiercingOtro2")}`,
          value: "Otros",
          image: imagesMicrodermalOther,
          type: "radio",
          classNameBorder: "borderBottom",
        },
      ],
    },
    {
      id: "otro",
      text: `${t("bodyPiercingData.bodyPartPiercingOtro2")}`,
      image: imagesMicrodermalOther,
      value: "Otro",
      type: "radio",
    },
  ];
};
