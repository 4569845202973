import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Form } from "react-bootstrap";
import { MessageLoading } from "../MessageLoading/MessageLoading";
import {
  nextComponent,
  saveChanges,
  backComponent,
  setFormComponent,
  setFormComponentInfo,
} from "../../utils/nextComponent";

export const Date = ({ block, props }) => {

  const [t] = useTranslation("global");
  const [messageLoading, setMessageLoading] = useState(null);
  const [date_budget, setDate] = useState("");
  const myRef = useRef(null);

  useEffect(() => {
    props.setShowWriting(true);
    if (block.conditional) {
      setMessageLoading(true);
      setTimeout(() => {
        setMessageLoading(false);
        props.setShowWriting(false);
        executeScroll();
      }, 1000);
    }
  }, []);

  const handleChange = (e) => {
    const value = e.target.value;
    setDate(value);
    if (value.length <= 2) {
      backComponent(props, block);
    }
    if (value.length >= 3) {
      setTimeout(() => {
        nextComponent(props, block);
      }, 1500);
    }
    setFormComponent(props, block, { date_budget: value });
    setFormComponentInfo(props, block, { date_budget: value });
    saveChanges(props, block);
  };

  const executeScroll = () => {
    setTimeout(() => {
      const element = myRef?.current;
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
        const elementRect = element.getBoundingClientRect();
        const absoluteElementTop = elementRect.top + window.scrollY;
        const middle = absoluteElementTop - window.innerHeight / 6;
        window.scrollTo({ top: middle, behavior: "smooth" });
        element.focus();
      }
    }, 500);
  };
  return (
    <>
      {messageLoading === true && <MessageLoading scrollRef={true} />}
      {messageLoading === false && (
        <Form.Group id="nombre">
          <div
            className="divGenericLabelFormChat marginTopLabelGenericFormChat"
            ref={props.arrayComponentInitial.length === 0 ? props.arrayComponent[1].component === block.component ? null : myRef
              : props.arrayComponentInitial[1].component === block.component ? null : myRef}
          >
            <Form.Label className="generic01LabelFormChat borderColorLabelVar backgroundColorContainer">
              <span className="textBoldFormChat"> {t("date.whatdate")}</span>
            </Form.Label>
          </div>
          <div className="divGenericInputFormChat mb-4">
            <div className="divInputFormChat">
              <Form.Control
                autoComplete="nope"
                id="inputNameFormChat"
                className="inputNameFormChat"
                type="text"
                placeholder={t("date.date")}
                value={date_budget}
                onChange={handleChange}
                maxLength={50}
                required
                autoFocus={true}
              />
            </div>
          </div>
        </Form.Group>
      )}
    </>
  );
};
